import { Drawer } from "flowbite-react";
import { useState } from "react";
import { FaBars } from "react-icons/fa6";
import { useTranslation } from "react-i18next";

export default function Menu() {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const handleClose = () => setIsOpen(false);

  const links = [
    {
      name: "home",
      path: "/"
    },
    {
      name: "shop",
      path: "/shop"
    },
    {
      name: "about",
      path: "/about-us"
    },
    {
      name: "Contact",
      path: "/contact-us"
    }
  ];

  return (
    <>
      <button onClick={() => setIsOpen(true)} className="hidden max-md:block">
        <FaBars className="w-5 h-5 hover:text-blue-500" />
      </button>

      <Drawer className="p-0 z-50" open={isOpen} onClose={handleClose}>
        <Drawer.Header style={{ maxHeight: '48px', paddingInline: '16px', paddingTop: '14px', paddingBottom: '10px' }} titleIcon={() => <p className="text-black">Menu</p>} />
        <Drawer.Items>
          <div className="border-t p-4">
            <ul className="flex flex-col gap-4">
              {
                links.map((item, index) => (
                  <li key={index}><a className="px-0 py-1 text-black hover:text-blue-600" href={item.path}>{t(item.name)}</a></li>
                ))
              }
            </ul>
          </div>
        </Drawer.Items>
      </Drawer>
    </>
  );
}
