import { Modal } from 'flowbite-react';
import i18next from 'i18next';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HiPlus } from "react-icons/hi";
import { FaXmark } from "react-icons/fa6";
import { BiSolidTrash } from "react-icons/bi";
import toast from 'react-hot-toast';

export default function RequirementsModal({ requirements, setRequirements }) {
  const { t } = useTranslation();
  const [openModal, setOpenModal] = useState(false);
  const [newRequirement, setNewRequirement] = useState({
    en: { title: "", details: "" },
    fr: { title: "", details: "" },
  });

  const handleInputChange = (lang, field, value) => {
    setNewRequirement((prev) => ({
      ...prev,
      [lang]: { ...prev[lang], [field]: value },
    }));
  };

  const addRequirement = () => {
    setRequirements([...requirements, newRequirement]);
    setNewRequirement({ en: { title: "", details: "" }, fr: { title: "", details: "" } });
    setOpenModal(false);
  };


  // Remove :
  const removeRequirement = (index) => {
    const updatedRequirements = requirements.filter((_, i) => i !== index);
    setRequirements(updatedRequirements);
  };

  const confirmRemove = (id) => {
    toast((toa) => (
      <div id="toast-interactive" className="w-full max-w-xs p-4 text-gray-500 bg-white rounded-lg shadow">
        <div className="flex">
          <div className="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-red-600 bg-red-200 rounded-lg">
            <BiSolidTrash className="w-5 h-5" />
            <span className="sr-only">delete icon</span>
          </div>
          <div className="ms-3 text-sm font-normal">
            <span className="mb-1 text-sm font-semibold text-black">{t("remove-item")}</span>
            <div className="mb-2 text-sm font-normal">{t("remove-item-txt")}</div>
            <div className="grid grid-cols-2 gap-2">
              <div>
                <button onClick={() => {
                  removeRequirement(id);
                  toast.dismiss(toa.id);
                }} className="inline-flex justify-center w-full px-2 py-1.5 text-xs font-medium text-center text-white bg-red-600 rounded-lg hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300">{t("yes")}</button>
              </div>
              <div>
                <button onClick={() => toast.dismiss(toa.id)} className="inline-flex justify-center w-full px-2 py-1.5 text-xs font-medium text-center text-black bg-white border border-gray-300 rounded-lg hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200">{t("cancel")}</button>
              </div>
            </div>
          </div>
          <button onClick={() => toast.dismiss(toa.id)} className="h-6 w-6 ms-auto -mx-1.5 -my-1.5 bg-white items-center justify-center flex-shrink-0 text-gray-400 hover:text-black rounded-lg focus:ring-2 focus:ring-gray-300 hover:bg-gray-100 inline-flex">
            <span className="sr-only">Close</span>
            <FaXmark className="w-4 h-4" />
          </button>
        </div>
      </div>),
      {
        style: { background: 'none', boxShadow: 'none' },
        duration: 5000,
        position: 'top-center',
      });
  }

  return (
    <div>
      <Modal size="xl" show={openModal} onClose={() => setOpenModal(false)}>
        <Modal.Header className='py-3'><p className='text-base/8'>{t("add-system-req")}</p></Modal.Header>
        <Modal.Body>
          <div className="mb-3">
            <label className="block mb-1 text-sm font-medium text-black">Title <strong className="text-blue-700">(en)</strong></label>
            <input type="text" className="bg-gray-50 myborder text-black text-sm rounded-lg focus:ring-blue-700 focus:border-blue-700 block w-full p-2.5"
              value={newRequirement.en.title} onChange={(e) => handleInputChange("en", "title", e.target.value)} />
          </div>

          <div className="mb-3">
            <label className="block mb-1 text-sm font-medium text-black">Titre <strong className="text-blue-700">(fr)</strong></label>
            <input type="text" className="bg-gray-50 myborder text-black text-sm rounded-lg focus:ring-blue-700 focus:border-blue-700 block w-full p-2.5"
              value={newRequirement.fr.title} onChange={(e) => handleInputChange("fr", "title", e.target.value)} />
          </div>

          <div className="mb-3">
            <label className="block mb-1 text-sm font-medium text-black"> Details <strong className="text-blue-700">(en)</strong></label>
            <textarea rows={3} className="bg-gray-50 myborder text-black text-sm rounded-lg focus:ring-blue-700 focus:border-blue-700 block w-full p-2.5"
              value={newRequirement.en.details} onChange={(e) => handleInputChange("en", "details", e.target.value)}></textarea>
          </div>

          <div className="mb-3">
            <label className="block mb-1 text-sm font-medium text-black"> Détails <strong className="text-blue-700">(fr)</strong></label>
            <textarea rows={3} className="bg-gray-50 myborder text-black text-sm rounded-lg focus:ring-blue-700 focus:border-blue-700 block w-full p-2.5"
              value={newRequirement.fr.details} onChange={(e) => handleInputChange("fr", "details", e.target.value)}></textarea>
          </div>

          <div className="flex justify-start gap-2 mt-4">
            <button onClick={addRequirement} className='py-2.5 px-4 bg-blue-600 hover:bg-blue-700 text-white rounded-md text-sm'>{t("add")}</button>
            <button className='py-2.5 px-4 bg-white hover:bg-gray-100 hover:text-blue-600 text-black rounded-md text-sm' onClick={() => setOpenModal(false)}>{t("cancel")}</button>
          </div>
        </Modal.Body>
      </Modal>

      <div className="flex justify-between gap-2 px-4 sm:px-0">
        <h3 className="text-lg font-semibold text-gray-900">{t("system-req")}</h3>
        <div className="cursor-pointer bg-blue-600 hover:bg-blue-700 text-white p-1.5 rounded" onClick={() => setOpenModal(true)}>
          <HiPlus className='w-[18px] h-[18px]' />
        </div>
      </div>
      {requirements.length > 0 ? (
        <div className="mt-6 border-t border-gray-100">
          <dl className="divide-y divide-gray-100">
            {requirements.map((req, index) => (
              <div className='flex items-center w-full gap-2' key={index}>
                <div className="flex-grow px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                  <dt className="text-sm/6 font-medium text-gray-900">{req[i18next.language].title}</dt>
                  <dd className="mt-1 text-sm/6 text-gray-700 sm:col-span-2 sm:mt-0">{req[i18next.language].details}</dd>
                </div>
                <div onClick={() => confirmRemove(index)} className="cursor-pointer bg-red-600 hover:bg-red-700 text-white h-[30px] w-[30px] flex items-center justify-center rounded">
                  ✖
                </div>
              </div>

            ))}
          </dl>
        </div>
      ) : <p className="mt-4 text-sm text-gray-500">{t("no-requirements")}</p>}
    </div>
  );
};
