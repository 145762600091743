export const FormatDate = ({ dateString }) => {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, '0'); // Get day and pad with zero if needed
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based, so add 1
  const year = date.getFullYear();

  return `${day}/${month}/${year}`;
};

export const FormatDateForcomment = ({ dateString }) => {
  // Convert to local time from UTC
  const date = new Date(dateString + 'Z'); // Adding 'Z' forces UTC interpretation

  // Options for formatting the date
  const options = {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  };

  return `${date.toLocaleDateString('en-US', options)}`;
};

export const FormatDateTable = ({ dateString }) => {
  const date = new Date(dateString + 'Z');

  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');

  return `${day}.${month}.${year}, ${hours}:${minutes}`;
};

export function FormatDateTableUsers({ dateString }) {
  const date = new Date(dateString);

  // Extract date components
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();

  // Extract time components
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');

  // Return formatted string
  return `${day}.${month}.${year}, ${hours}:${minutes}`;
}