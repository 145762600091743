import i18n from 'i18next';
import AddCart from "./AddCart";
import FavoriteBtn from "./FavoriteBtn";
import { blankimage } from "../assets/images";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import axios from "axios";
import { HiMiniStar } from "react-icons/hi2";
import { useRefresh } from "../general additions/RefreshProvider";

export default function ProductCard({ data, typeProp }) {
  const { t } = useTranslation();
  const localhostImage = process.env.REACT_APP_LOCALHOST_IMG;
  const localhost = process.env.REACT_APP_LOCALHOST;
  const token = localStorage.getItem("token");
  const [discount, setDiscount] = useState([]);
  const [discountText, setDiscountText] = useState("");
  const [exist, setExist] = useState(false);
  const { refreshKey } = useRefresh();

  useEffect(() => {
    axios.get(`${localhost}/admin/getdiscountsbyid/${data.productID}`)
      .then((res) => {
        setDiscount(res.data);
        setDiscountText(res.data.percentage ? parseInt(res.data.percentage) + '%' : parseFloat(res.data.amount) + '$');
        setExist(res.data.discountID ? true : false);
      }).catch((err) => console.error(err));
  }, [refreshKey]);

  // Check if data is available and ensure proper parsing
  if (!data) {
    return <p className="text-base text-black">Loading...</p>;
  }

  let productName;

  try {
    if (typeProp === "notJson") {
      productName = data.name;
    } else {
      productName = JSON.parse(data.name);
    }
  } catch (error) {
    console.error("Error parsing JSON data: ", error);
    return <p className="text-base text-red-500">{t("error-displaying-infos")}</p>;
  }

  return (
    <div>
      <div className="relative rounded-md overflow-hidden h-auto max-w-md mx-auto">
        <a href={"/product/" + data.slug}>
          <img className={`object-fill object-center ${data.stock > 0 ? "" : "grayscale"}`} src={data.image ? localhostImage + data.image : blankimage} alt={data.name[i18n.language]} />
          {exist ? <span className={`absolute top-2 right-2 bg-blue-600 text-white text-xs px-2 py-1 rounded-full`}>
              -{discountText}</span> : null}
        </a>
      </div>

      <div className='px-1'>
        <div className="flex items-center justify-between gap-4 mt-0.5">
          {exist ? <span className="me-2 rounded text-xs font-medium text-red-700">{t("up-to-discount", { value: discountText })}</span> : <div></div>}
          <div className="flex items-center justify-end gap-1">
            {token ? <FavoriteBtn prodID={data.productID} typeBtn="icon" /> : null}
          </div>
        </div>

        <a href={"/product/" + data.slug} className="text-base font-semibold leading-tight text-black hover:underline">
          {productName[i18n.language].length > 58 ? productName[i18n.language].substring(0, 58) + "..." : productName[i18n.language]}
        </a>

        <div className="mt-1 flex flex-wrap items-center gap-1">
          <span className="text-sm font-medium text-black">{typeof data.countreview === 'number' ? `${data.countreview}` : null}</span>
          <div className="flex flex-wrap gap-0.5 text-xs items-center px-1 py-0.5 bg-[#fbbf23] text-white rounded-full">
            <HiMiniStar className="h-3 w-3" />
            {data.ratings.toFixed(1)}
          </div>
        </div>

        <div className="mt-1 flex items-end justify-between gap-4">
          <p className="text-xl font-extrabold leading-tight text-black">
            {exist ? <span className="text-red-700 line-through text-xs font-medium">${data.price.toFixed(2)}</span> : null}
            ${exist ? discount.newPrice.toFixed(2) : data.price.toFixed(2)}
          </p>

          <AddCart prodID={data.productID} prodSlug={data.slug} stockProd={data.stock} />
        </div>
      </div>
    </div>
  );
}
