import axios from "axios";
import { useState, useEffect, useRef, useCallback } from "react";
import { LoadingShopPart } from "../general additions/LoadingElements";
import { HiArrowUpTray } from "react-icons/hi2";
import { HiPlus } from "react-icons/hi";
import { RichTextEditor } from '@mantine/rte';
import { useTranslation } from "react-i18next";
import OwnToast from "../general additions/OwnToast";
import { useNavigate, useParams } from "react-router";
import { getAllCategories } from "../general additions/getCategories";
import i18next from "i18next";
import RequirementsModal from "./admin elements/RequirementsModal";
import { getAllLicenses } from "../general additions/getLicenses";
import { Modal } from "flowbite-react";
import Cropper from "react-easy-crop";

export default function Uproduct() {
  const { t } = useTranslation();
  const { slug } = useParams();
  const localhost = process.env.REACT_APP_LOCALHOST;
  const localhostImage = process.env.REACT_APP_LOCALHOST_IMG;
  const navigate = useNavigate();
  const token = localStorage.getItem('token');
  const uniqueID = localStorage.getItem('uniqueID');

  const [loading, setLoading] = useState(true);
  const [categories, setCategories] = useState([]);
  const [category, setCategory] = useState(1);
  const [image, setImage] = useState(null);
  const [originalImage, setOriginalImage] = useState(null);
  const [nameEn, setNameEn] = useState("");
  const [nameFr, setNameFr] = useState("");
  const [price, setPrice] = useState("");
  const [stock, setStock] = useState("");
  const [descriptionEn, setDescriptionEn] = useState("");
  const [descriptionFr, setDescriptionFr] = useState("");

  // added elements :
  const [licenses, setLicenses] = useState([]);
  const [license, setLicense] = useState(1);
  const [requirements, setRequirements] = useState([]);
  const [durationNb, setDurationNb] = useState("");
  const [durationDate, setDurationDate] = useState("");

  // Remove findDOMNode error :
  const originalError = console.error;
  console.error = (...args) => {
    if (/findDOMNode is deprecated/.test(args[0])) {
      return;
    }
    originalError(...args);
  };

  useEffect(() => {
    const fetchCategoryData = async () => {
      // Category :
      const categoryData = await getAllCategories();
      if (categoryData) {
        setCategories(categoryData);
      }
      // Lisence :
      const LicenseData = await getAllLicenses();
      if (LicenseData) {
        setLicenses(LicenseData);
      }
    };
    fetchCategoryData();

    axios.get(`${localhost}/product/${slug}`, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
      }
    }).then((res) => {
      const data = res.data[0];
      data.name = JSON.parse(data.name);
      data.details = JSON.parse(data.details);
      data.technical_specs = JSON.parse(data.technical_specs);

      if (data.duration) {
        const duration = JSON.parse(data.duration);
        const durationValue = parseInt(duration.en.split(' ')[0]);
        const durationType = duration.en.split(' ')[1].toLowerCase();
        setDurationNb(durationValue);
        setDurationDate(durationType);
      }

      setNameEn(data.name.en);
      setNameFr(data.name.fr);
      setPrice(data.price);
      setStock(data.stock);
      setDescriptionEn(data.details.en);
      setDescriptionFr(data.details.fr);
      setImage(data.image);
      setOriginalImage(data.image);
      setCategory(data.category_id);
      setLicense(data.license_id);
      setRequirements(data.technical_specs ? data.technical_specs : []);
      setLoading(false)
    })
      .catch((err) => console.error(err));
  }, []);

  // duration :
  const durationJson = () => {
    if (durationNb !== "" & durationDate !== "") {
      return {
        en: `${durationNb} ${i18next.getFixedT('en')(durationDate)}`,
        fr: `${durationNb} ${i18next.getFixedT('fr')(durationDate)}`,
      }
    }
  };

  const formats = [
    ['bold', 'italic', 'underline', 'link', 'image'],
    ['unorderedList', 'h1', 'h2', 'h3'],
    ['sup', 'sub'],
    ['alignLeft', 'alignCenter', 'alignRight'],
  ];

  const handleSubmit = (e) => {
    e.preventDefault();
    const productData = {
      name: {
        en: nameEn,
        fr: nameFr
      },
      details: {
        en: descriptionEn,
        fr: descriptionFr
      },
      price: parseFloat(price),
      stock: parseInt(stock),
      category_id: category,
      license_id: parseInt(license),
      duration: durationJson(),
      image: image,
      ratings: 0,
      uniqueID: uniqueID,
      technical_specs: requirements,
    };
    axios.post(`${localhost}/edit/${slug}`, productData, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
      }
    })
      .then((res) => {
        setTimeout(() => { navigate("/admin/dashboard/products") }, 1500);
        OwnToast('success', t("update-product-success"));
      })
      .catch((err) => { console.error(err); OwnToast('error', t("update-product-error")); });
  }

  // Crop image :
  const [openModal, setOpenModal] = useState(false);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const imageRef = useRef(null);
  const [cropSize, setCropSize] = useState({ width: 450, height: 500 });

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => setCroppedAreaPixels(croppedAreaPixels), []);

  const createImage = (url) => new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener('load', () => resolve(image));
    image.addEventListener('error', (error) => reject(error));
    image.src = url;
  });

  const getCroppedImg = async () => {
    try {
      const currentImage = imageRef.current;
      if (!currentImage) {
        console.error('No image to crop');
        return null;
      }

      const imageUrl = typeof currentImage === 'string' ? localhostImage + currentImage : URL.createObjectURL(currentImage);
      const image = await createImage(imageUrl);
      const canvas = document.createElement('canvas');
      canvas.width = croppedAreaPixels.width;
      canvas.height = croppedAreaPixels.height;
      const ctx = canvas.getContext('2d');
      ctx.drawImage(
        image,
        croppedAreaPixels.x,
        croppedAreaPixels.y,
        croppedAreaPixels.width,
        croppedAreaPixels.height, 0, 0,
        croppedAreaPixels.width,
        croppedAreaPixels.height
      );

      return new Promise((resolve, reject) => {
        canvas.toBlob((file) => {
          const croppedFile = new File([file], 'cropped-image.jpg', { type: 'image/jpeg' });
          resolve(croppedFile);
        }, 'image/jpeg');
      });
    } catch (e) {
      console.error('Error creating cropped image', e);
      return null;
    }
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImage(file);
      imageRef.current = file;
      setOpenModal(true);
    }
  };

  const handleCropConfirm = async () => {
    const croppedImage = await getCroppedImg();
    if (croppedImage) {
      setImage(croppedImage);
      setCroppedImage(croppedImage);
      setOpenModal(false);
    }
  };

  const handleClose = () => {
    setImage(originalImage);
    setOpenModal(false);
  };

  useEffect(() => {
    imageRef.current = image;
  }, [image]);


  if (loading) {
    return (<div className="flex justify-center items-center min-h-[80vh] bg-white shadow p-4 max-sm:p-1.5 rounded-md max-sm:rounded-none antialiased">
      <LoadingShopPart sizeProp={120} />
    </div>)
  }
  return (
    <>
      <div className="container bg-white shadow p-4 max-sm:p-1.5 rounded-md max-sm:rounded-none antialiased">
        <form className="px-4 max-sm:px-1 max-sm:py-2 mx-auto 2xl:px-0">
          <div className="lg:grid lg:grid-cols-2 lg:gap-6 xl:gap-8 ">

            <label className="bg-gray-50 text-black hover:bg-blue-100 hover:text-blue-600 hover:border-blue-600 flex justify-center items-center myborder rounded-lg cursor-pointer" htmlFor="productImage">
              <div className="flex justify-center items-center shrink-0 px-12 max-w-[448px] max-h-[480px] mx-auto">
                {image ? (
                  <img src={image instanceof File ? URL.createObjectURL(image) : localhostImage + image} alt="Uploaded Product" className="max-w-full max-h-full object-contain" />
                ) : (
                  <div className="flex flex-col justify-center h-60 items-center">
                    <HiArrowUpTray className="text-black h-20 w-auto" />
                    <p className="mt-3 text-black text-center text-xs sm:text-sm">
                      <strong>Upload Image</strong> <br />Select an image to upload
                    </p>
                  </div>
                )}
                <input id="productImage" type="file" onChange={handleImageUpload} accept="image/jpeg, image/jpg, image/png" style={{ display: 'block', opacity: 0, position: 'absolute', pointerEvents: 'none', height: 0 }} required />
              </div>
            </label>

            <div className="max-md:mt-6">
              <div className="max-w-md mb-5">
                <label className="block mb-2 text-sm font-medium text-black">{t("category")}</label>

                <div className="flex flex-wrap gap-1">
                  <select value={category} onChange={(e) => setCategory(e.target.value)} className='flex flex-wrap items-center justify-center gap-2 cursor-pointer text-black text-sm font-medium rounded-full px-2.5 py-1.5'>
                    {categories.map((item, index) => <option key={index} value={item.categoryID}>{item.title[i18next.language]}</option>)}
                  </select>
                  <a href="https://shop.sharedstore.ma/admin/dashboard/categories_list" target="_blank" className="flex items-center justify-center bg-blue-600 hover:bg-blue-700 text-white w-[34px] h-[34px] rounded-full">
                    <HiPlus className='w-5 h-5' />
                  </a>
                </div>
              </div>

              <div className="max-w-md mb-5">
                <label className="block mb-2 text-sm font-medium text-black">Product name <strong className="text-blue-700">(en)</strong></label>
                <input type="text" value={nameEn} onChange={(e) => setNameEn(e.target.value)} className="bg-gray-50 myborder text-black text-sm rounded-lg focus:ring-blue-700 focus:border-blue-700 block w-full p-2.5" placeholder="Here write the product name..." />
              </div>
              <div className="max-w-md mb-5">
                <label className="block mb-2 text-sm font-medium text-black">Nom du produit <strong className="text-blue-700">(fr)</strong></label>
                <input type="text" value={nameFr} onChange={(e) => setNameFr(e.target.value)} className="bg-gray-50 myborder text-black text-sm rounded-lg focus:ring-blue-700 focus:border-blue-700 block w-full p-2.5" placeholder="Écrivez ici le nom du produit..." />
              </div>
              <div className="flex grid-cols-2 gap-4 max-w-md mb-5">
                <div className="col-span-1 w-full">
                  <label className="block mb-2 text-sm font-medium text-black">Price <strong className="text-blue-700">($)</strong></label>
                  <input type="text" value={price} onChange={(e) => setPrice(e.target.value)} className="w-full bg-gray-50 myborder text-black text-sm rounded-lg focus:ring-blue-700 focus:border-blue-700 block p-2.5" placeholder="10.99" />
                </div>
                <div className="col-span-1 w-full">
                  <label className="block mb-2 text-sm font-medium text-black">Stock</label>
                  <input type="text" value={stock} onChange={(e) => setStock(e.target.value)} className="w-full bg-gray-50 myborder text-black text-sm rounded-lg focus:ring-blue-700 focus:border-blue-700 block p-2.5" placeholder="0" />
                </div>
              </div>
              <div className="flex grid-cols-2 gap-4 max-w-md mb-5">
                <div className="col-span-1 w-full">
                  <label className="block mb-2 text-sm font-medium text-black">{t("type-licence")}</label>
                  <div className="relative flex items-center rounded-lg">
                    <select value={license} onChange={(e) => setLicense(e.target.value)} className="border border-r-0 border-gray-300 rounded-l-lg bg-transparent w-full text-sm focus:outline-none ring-0 focus:border-0 block p-2.5">
                      {licenses.map((item, index) => <option key={index} className="bg-transparent" value={item.licenseID}>{item.title[i18next.language]}</option>)}
                    </select>
                    <a href="https://shop.sharedstore.ma/admin/dashboard/licenses_list" target="_blank" className="flex items-center justify-center text-white bg-blue-600 hover:bg-blue-700 rounded-r-lg w-11 h-[42px]">
                      <HiPlus className='w-5 h-5' />
                    </a>
                  </div>
                </div>
                <div className="col-span-1 w-full">
                  <label className="block mb-2 text-sm font-medium text-black">{t("duration")}</label>
                  <div className="relative flex items-center rounded-lg">
                    <input value={durationNb} onChange={(e) => setDurationNb(e.target.value)} style={{ border: "1px solid #D1D5DB" }} placeholder="0" type="number"
                      className="min-w-8 rounded-l-lg bg-transparent w-full text-sm focus:outline-none ring-0 focus:border-0 block p-2.5" />
                    <select value={durationDate} onChange={(e) => setDurationDate(e.target.value)} className="border border-l-0 border-gray-300 rounded-r-lg bg-transparent w-full text-sm focus:outline-none ring-0 focus:border-0 block p-2.5">
                      {["days", "months", "years"].map((item, index) => (
                        <option key={index} value={item}>{t(item)}</option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* System requirements : */}
          <div className="mt-6 pt-4 border-t">
            <RequirementsModal requirements={requirements} setRequirements={setRequirements} />
          </div>

          {/* Description : */}
          <div className='mt-6 pt-4 border-t'>
            <h3 className="text-lg font-semibold text-gray-900 mb-4">Description</h3>
            <div className="mb-5 max-sm:col-span-2">
              <label className="block mb-2 text-sm font-medium text-black">Description <strong className="text-blue-700">(en)</strong></label>
              <RichTextEditor style={{ minHeight: "250px" }} value={descriptionEn} onChange={setDescriptionEn} controls={formats} />
            </div>

            <div className="mb-5 max-sm:col-span-2">
              <label className="block mb-2 text-sm font-medium text-black">Description <strong className="text-blue-700">(fr)</strong></label>
              <RichTextEditor style={{ minHeight: "250px" }} value={descriptionFr} onChange={setDescriptionFr} controls={formats} />
            </div>
          </div>

          <div className="flex w-full justify-end">
            <button onClick={handleSubmit} className="text-white mt-5 sm:mt-0 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 focus:outline-none flex items-center justify-center">
              <HiPlus className="w-5 h-5 -ms-2 me-2" />
              {t("edit-prod")}
            </button>
          </div>
        </form>
      </div>

      {/* Crop Modal */}
      <Modal show={openModal} onClose={() => setOpenModal(false)} size="4xl">
        <Modal.Header>Crop Image</Modal.Header>
        <Modal.Body>
          <div className="relative h-[500px]">
            {image && (
              <Cropper image={image instanceof File ? URL.createObjectURL(image) : localhostImage + image} crop={crop} zoom={zoom} aspect={cropSize.width / cropSize.height}
                onCropChange={setCrop} onCropComplete={onCropComplete} onZoomChange={setZoom} />
            )}
          </div>
          <div className="mt-4 flex justify-center">
            <input type="range" value={zoom} min={1} max={3} step={0.1} aria-labelledby="Zoom" onChange={(e) => setZoom(Number(e.target.value))} className="w-full max-w-xs" />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button onClick={handleCropConfirm} className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700">Crop Image</button>
          <button onClick={handleClose} className="bg-gray-200 text-black px-4 py-2 rounded hover:bg-gray-300">Cancel</button>
        </Modal.Footer>
      </Modal>
    </>
  )
};