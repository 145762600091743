import React, { useEffect, useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { getUserInfo } from "./getUserInfo";
import { LoadingShopPart } from "../general additions/LoadingElements";

const ProtectedRoute = ({ allowedRoles }) => {
  const [userRole, setUserRole] = useState(null);
  const [loading, setLoading] = useState(true); 

  useEffect(() => {
    const fetchUserData = async () => {
      const data = await getUserInfo();
      if (data) {
        setUserRole(data.role);
      }
      setLoading(false);
    };
    fetchUserData();
  }, []);

  // Show a loading state while fetching user data
  if (loading) {
    return <div className="flex justify-center items-center min-h-screen"><LoadingShopPart sizeProp={150}/></div>;
  }

  // If no role is available, redirect to login
  if (!userRole) {
    return <Navigate to="/login" replace />;
  }

  // Check if the user's role is allowed
  if (!allowedRoles.includes(userRole)) {
    return <Navigate to="/not-found" replace />;
  }

  return <Outlet />; // Render the child routes if authenticated and has the right role
};

// Admin Route (Only for Admin)
export const AdminRoute = () => <ProtectedRoute allowedRoles={["admin"]} />;

// User & Admin Route (for both)
export const UserRoute = () => <ProtectedRoute allowedRoles={["user", "admin"]} />;
