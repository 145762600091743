import React, { useState } from 'react';
import { HiPlus } from "react-icons/hi";
import { PiPercentBold } from "react-icons/pi";
import { BiDollar } from "react-icons/bi";
import { Modal } from 'flowbite-react';
import { useTranslation } from 'react-i18next';
import OwnToast from '../../general additions/OwnToast';
import axios from 'axios';
import { useRefresh } from '../../general additions/RefreshProvider';

export default function AddDiscount({ productID }) {
  const { t } = useTranslation();
  const { triggerRefresh } = useRefresh();
  const token = localStorage.getItem('token');
  const localhost = process.env.REACT_APP_LOCALHOST;
  const [openModal, setOpenModal] = useState(false);

  // Discount :
  const [percentage, setPercentage] = useState("");
  const [amount, setAmount] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const modalOpen = async () => {
    const discountbyid = await axios.get(`${localhost}/admin/getdiscountsbyid/${productID}`);

    if (discountbyid.data) {
      OwnToast('warning', <span className='font-semibold'>{t("discount-already-exist-1")}<br /><span className='text-xs font-medium'>{t("discount-already-exist-2")}</span></span>, 5500, 1000);
    } else {
      setOpenModal(true);
      setPercentage("");
      setAmount("");
      setStartDate("");
      setEndDate("");
    }
  };

  const modalClose = () => {
    setOpenModal(false);
    setPercentage("");
    setAmount("");
    setStartDate("");
    setEndDate("");
  };

  const handleAddDiscount = (e) => {
    e.preventDefault();

    // Convert dates to comparable format
    const start = new Date(startDate);
    const end = new Date(endDate);
    const now = new Date();

    // Validation logic
    if (start > end) {
      OwnToast('warning', t("start>end"), 3000);
      return;
    }

    if (end < now) {
      OwnToast('warning', t("end<now"), 3000);
      return;
    }

    const discountData = {
      type: "discount",
      code: null,
      percentage: percentage === "" ? null : percentage,
      amount: amount === "" ? null : amount,
      start_date: startDate,
      end_date: endDate,
      product_id: productID,
    };

    axios.post(`${localhost}/admin/discounts`, discountData, { headers: { 'Authorization': `Bearer ${token}` } })
      .then((res) => {
        OwnToast('success', t("add-discount-success"));
        triggerRefresh();
        modalClose();
      })
      .catch((err) => {
        OwnToast('error', t("add-discount-error"));
      });
  };

  return (
    <>
      <button onClick={modalOpen} className='myborder px-2 py-1.5 text-black text-sm rounded-md hover:bg-gray-100'>
        <HiPlus className='w-4 h-4' />
      </button>

      <Modal size="xl" show={openModal} onClose={modalClose}>
        <Modal.Header>{t("create-new-discount")}</Modal.Header>
        <form onSubmit={handleAddDiscount}>
          <Modal.Body>
            <div>
              <div className='grid grid-cols-2 gap-3 mb-5'>
                <div className="col-span-1">
                  <label className="block mb-2 text-sm font-medium text-black">{t("percentage")}</label>
                  <div className="relative">
                    <input type="number" value={percentage} onChange={(e) => setPercentage(e.target.value)} className="placeholder:lowercase disabled:cursor-not-allowed bg-gray-50 myborder text-black text-sm rounded-lg focus:ring-blue-700 focus:border-blue-700 block w-full p-2.5" placeholder={t("percentage")}
                      disabled={amount ? true : false} />
                    <PiPercentBold className='absolute inset-y-0 end-2 top-[11px] flex items-center pointer-events-none w-auto h-5 text-gray-500' />
                  </div>
                </div>
                <div className="col-span-1">
                  <label className="block mb-2 text-sm font-medium text-black">{t("amount")}</label>
                  <div className='relative'>
                    <input type="number" value={amount} onChange={(e) => setAmount(e.target.value)} className="placeholder:lowercase disabled:cursor-not-allowed bg-gray-50 myborder text-black text-sm rounded-lg focus:ring-blue-700 focus:border-blue-700 block w-full p-2.5" placeholder={t("amount")}
                      disabled={percentage ? true : false} />
                    <BiDollar className='absolute inset-y-0 end-2 top-[11px] flex items-center pointer-events-none w-auto h-5 text-gray-500' />
                  </div>
                </div>
              </div>

              <div className='grid grid-cols-2 gap-3 mb-5'>
                <div className="col-span-1">
                  <label className="block mb-2 text-sm font-medium text-black">{t("startdate")}</label>
                  <input type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)} className="placeholder:lowercase bg-gray-50 myborder text-black text-sm rounded-lg focus:ring-blue-700 focus:border-blue-700 block w-full p-2.5" placeholder={t("startdate")} required />
                </div>
                <div className="col-span-1">
                  <label className="block mb-2 text-sm font-medium text-black">{t("enddate")}</label>
                  <input type="date" value={endDate} onChange={(e) => setEndDate(e.target.value)} className="placeholder:lowercase bg-gray-50 myborder text-black text-sm rounded-lg focus:ring-blue-700 focus:border-blue-700 block w-full p-2.5" placeholder={t("enddate")} required />
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button type='submit' className='py-2.5 px-4 bg-blue-600 hover:bg-blue-700 text-white rounded-md text-sm'>{t("create")}</button>
            <button className='py-2.5 px-4 bg-white hover:bg-gray-100 hover:text-blue-600 text-black rounded-md text-sm' onClick={modalClose}>{t("cancel")}</button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  )
}
