import { useTranslation } from "react-i18next";
import RatingStars from "../../elements/RatingStars";
import ComentModel from "./ComentModel";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import { FormatDateForcomment } from "../../elements/FormatDate";
import { useRefresh } from "../../general additions/RefreshProvider";
import RemoveReviewBtn from "./RemoveReviewBtn";
import { getUserInfo } from "../../auth/getUserInfo";

export default function Reviews({ productId, ratingProd, countRev }) {
  const { t } = useTranslation();
  const localhost = process.env.REACT_APP_LOCALHOST;
  const token = localStorage.getItem("token");
  const [userId, setUserId] = useState(0);
  const [reviews, setReviwes] = useState([]);
  const [showAll, setShowAll] = useState(false);
  const [btnShowAll, setBtnShowAll] = useState(false);
  const { refreshKey } = useRefresh();

  useEffect(() => {
    axios.get(localhost + "/review/" + productId)
      .then((res) => {
        const all = showAll ? res.data : res.data.slice(0, 4);
        setBtnShowAll(res.data.length > 4);
        setReviwes(all);
      })
      .catch((err) => console.error(err));

    const fetchUserData = async () => {
      const data = await getUserInfo();
      if (data) {
        setUserId(data.userID)
      }
    };
    fetchUserData();
  }, [showAll, refreshKey]);

  return (
    <section className="antialiased">
      <div className="mx-auto max-w-screen-xl px-4 max-md:px-0 2xl:px-0">

        <div className="flex justify-between items-center gap-4 max-md:gap-10 max-md:flex-col">
          <div className="flex text-left flex-col">
            <h2 className="text-2xl mb-2 font-semibold text-black text-left max-md:text-center">{t("reviews")}</h2>

            <div className="mt-2 flex items-center gap-2 sm:mt-0">
              <div className="flex items-center gap-0.5">
                <RatingStars size={4} rating={ratingProd} />
              </div>
              <p className="text-sm font-medium leading-none text-gray-500">({ratingProd.toFixed(1)})</p>
              <p className="text-sm font-medium leading-none text-black underline hover:no-underline"> {countRev} {t("reviews")} </p>
            </div>
          </div>

          <div className="shrink-0 space-y-4">
            <p className="text-2xl font-semibold leading-none text-right max-md:text-center text-black">{ratingProd.toFixed(2)} {t("outof")} 5</p>
            {
              token ? <ComentModel productID={productId} /> : null
            }
          </div>
        </div>

        <div className="mt-6 divide-y divide-gray-200">
          {
            reviews.map((item, index) => (
              <div key={index} className="gap-3 py-6 sm:flex sm:items-start">
                <div className="shrink-0 space-y-2 sm:w-48 md:w-72">
                  <div className="flex items-center gap-0.5">
                    <RatingStars rating={item.rating} size={4} />
                  </div>

                  <div className="space-y-0.5">
                    <p className="text-base font-semibold text-black">{item.fname && item.lname ? `${item.fname} ${item.lname}` : item.username}</p>
                    <p className="text-sm font-normal text-gray-500"><FormatDateForcomment dateString={item.created_at} /></p>
                  </div>
                </div>

                <div className="mt-4 w-full min-w-0 flex sm:mt-0">
                  <p className="flex-grow text-base font-normal text-gray-500">{item.comment}</p>
                  {
                    userId === item.user_id ?
                      <RemoveReviewBtn rev_id={item.reviewID} />
                      : null
                  }
                </div>
              </div>
            ))
          }
        </div>
        {
          btnShowAll ? <button onClick={() => setShowAll(!showAll)} className="my-3 text-sm font-medium text-black hover:text-blue-700">
            {showAll ? t("viewless") : t("viewmore")}
          </button> : null
        }
      </div>
    </section>
  )
}
