import React from 'react'
import CategoriesBar from './CategoriesBar'

export default function AllShop() {
  return (
    <div>
      <CategoriesBar/>
    </div>
  )
}
