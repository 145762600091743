import React, { createContext, useContext, useState } from "react";

// Create a context to hold the refresh state
const RefreshContext = createContext();

// Create a provider to share the refresh function
export const RefreshProvider = ({ children }) => {
  const [refreshKey, setRefreshKey] = useState(0);

  const triggerRefresh = () => setRefreshKey(prev => prev + 1); // Increment the key

  return (
    <RefreshContext.Provider value={{ refreshKey, triggerRefresh }}>
      {children}
    </RefreshContext.Provider>
  );
};

// Hook to use the refresh in any component
export const useRefresh = () => useContext(RefreshContext);
