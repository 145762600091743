import React, { useEffect, useState } from 'react';
import { HiFunnel } from "react-icons/hi2";
import { Drawer } from 'flowbite-react';
import { useTranslation } from 'react-i18next';
import { getAllCategories } from '../../general additions/getCategories';
import { useRefresh } from '../../general additions/RefreshProvider';
import i18next from 'i18next';

export default function FilterBar({ onCategorySelect }) {
  const { t } = useTranslation();
  const { refreshKey } = useRefresh();
  const [isOpen, setIsOpen] = useState(false);
  const [categories, setCategories] = useState([]);
  const handleClose = () => setIsOpen(false);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const categoryData = await getAllCategories();
        if (categoryData) {
          setCategories(categoryData);
        }
      } catch (err) {
        console.error(err);
      }
    };
    fetchProducts();
  }, [refreshKey]);

  return (
    <>
      <button onClick={() => setIsOpen(true)} className="flex w-full items-center justify-center md:hidden rounded-lg border border-gray-200 bg-white px-3 py-2 text-sm font-medium text-black hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:outline-none focus:ring-4 focus:ring-gray-100 sm:w-auto">
        <HiFunnel className="-ms-0.5 me-2 h-4 w-4" />
        {t("filter")}
      </button>

      <Drawer style={{ marginLeft: "0px", marginRight: "0px" }} open={isOpen} onClose={handleClose}>
        <Drawer.Header title={t("product-categories")} titleIcon={() => <></>} />
        <Drawer.Items>
          <div className='flex flex-col col-span-1 gap-2 mt-5'>
            <button onClick={() => { onCategorySelect("All-products"); handleClose() }}
              className='flex flex-wrap items-center gap-2 py-2 text-black hover:text-blue-700 text-sm font-semibold'>
              {t("All-products")}
            </button>
            {categories.map((item, index) => (
              <button key={index} onClick={() => { onCategorySelect(item.categoryID); handleClose() }}
                className='flex flex-wrap items-center gap-2 py-2 text-black hover:text-blue-700 text-sm font-semibold'>
                {item.title[i18next.language]}
              </button>
            ))}
          </div>
        </Drawer.Items>
      </Drawer>
    </>
  );
}
