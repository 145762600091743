import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GrCart } from "react-icons/gr";
import { BsCurrencyDollar } from "react-icons/bs";
import { LuUsers } from "react-icons/lu";
import { AiOutlineProduct } from "react-icons/ai";
import { IoMdArrowUp, IoMdArrowDown } from "react-icons/io";
import axios from 'axios';
import { LoadingShopPart } from '../../general additions/LoadingElements';

export default function DashboardMain() {
  const { t } = useTranslation();
  const [fourStats, setFourStats] = useState([]);
  const [loading, setLoading] = useState(true);
  const localhost = process.env.REACT_APP_LOCALHOST;
  const token = localStorage.getItem("token");

  useEffect(() => {
    const fetchFourStats = async () => {
      const response = await axios.get(`${localhost}/admin/fourstats`, { headers: { Authorization: `Bearer ${token}` } });
      if (response) {
        setFourStats(response.data);
        setLoading(false);
      }
    }

    fetchFourStats();
  }, []);

  const stats = [
    {
      icon: <AiOutlineProduct className="shrink-0 size-4 w-5 h-5" />,
      title: 'Total Product',
      stat: fourStats.product_count,
    },
    {
      icon: <BsCurrencyDollar className="shrink-0 size-4 w-5 h-5" />,
      title: 'Total Profit',
      stat: `$${fourStats.revenue_total}`,
      avgnumber: 4.50,
      avgstatus: 'up'
    },
    {
      icon: <LuUsers className="shrink-0 size-4 w-5 h-5" />,
      title: 'Total Users',
      stat: fourStats.user_count,
      avgnumber: 1.50,
      avgstatus: 'down'
    },
    {
      icon: <GrCart className="shrink-0 size-4 w-5 h-5" />,
      title: 'Total Orders',
      stat: fourStats.orders_count,
      avgnumber: 0.25,
      avgstatus: 'up'
    }
  ];

  if (loading) {
    return (
      <div className='flex justify-center items-center h-[60vh] w-full'>
        <LoadingShopPart />
      </div>
    )
  }

  return (
    <div className="antialiased">
      <div className="flex justify-between gap-2 items-center max-sm:p-1.5 mb-3 max-sm:mb-0">
        <p className="text-black font-semibold text-xl max-sm:text-lg">{t("dashboard")}</p>

        <div className='flex flex-wrap items-center gap-1 max-md:hidden'>
          {
            ["All", "7 days", "30 days", "90 days"].map((item, index) => (
              <button key={index} className='rounded-full bg-black text-white px-3 py-1.5 text-xs'>{item}</button>
            ))
          }
        </div>
      </div>

      <div className='grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-6 xl:grid-cols-4 2xl:gap-7.5'>
        {
          stats.map((item, index) => (
            <div key={index} className='bg-white text-black rounded-lg border shadow-sm p-7'>
              <div className='w-max p-3 rounded-full bg-black text-white mb-3'>
                {item.icon}
              </div>

              <div className='flex justify-between'>
                <div className='flex flex-col'>
                  <p className='text-2xl font-bold'>{item.stat}</p>
                  <p className='text-xs font-semibold text-gray-600'>{item.title}</p>
                </div>
                <p className={`flex flex-wrap items-end text-sm font-semibold ${item.avgstatus === 'up' ? "text-green-500" : item.avgstatus === 'down' ? "text-red-500" : "text-gray-500"}`}>
                  {item.avgnumber ? `${item.avgnumber}%` : '--'}
                  {item.avgstatus === 'up' ? <IoMdArrowUp className="h-5 w-5" /> : item.avgstatus === 'down' ? <IoMdArrowDown className="h-5 w-5" /> : null}
                </p>
              </div>
            </div>
          ))
        }
      </div>
    </div>
  )
}
