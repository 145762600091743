import { HiTrash } from 'react-icons/hi2';
import axios from 'axios';
import React from 'react'
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { FaXmark } from "react-icons/fa6";
import OwnToast from '../general additions/OwnToast';
import { useRefresh } from '../general additions/RefreshProvider';

export default function RemoveOrder({ prod_id, xMark }) {
  const { t } = useTranslation();
  const localhost = process.env.REACT_APP_LOCALHOST;
  const token = localStorage.getItem("token");
  const { triggerRefresh } = useRefresh();

  const remove = () => {
    if (token) {
      const data = {
        "productId": parseInt(prod_id)
      };
      axios.post(localhost + "/orders/delete", data, { headers: { 'Authorization': `Bearer ${token}` } })
        .then(() => {
          triggerRefresh();
          OwnToast('success', t("s-remove-cart"));
        }).catch((err) => OwnToast('error', t("e-remove-cart")));
    } else {
      let cart = JSON.parse(localStorage.getItem('shoppingCart')) || [];
      cart = cart.filter(item => item.product_id !== prod_id);
      localStorage.setItem('shoppingCart', JSON.stringify(cart));

      setTimeout(() => {
        triggerRefresh();
        OwnToast('success', t('s-remove-cart'));
      }, 500);
    }
  }

  const confirmRemove = () => {
    toast((toa) => (
      <div id="toast-interactive" className="w-full max-w-xs p-4 text-gray-500 bg-white rounded-lg shadow">
        <div className="flex">
          <div className="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-red-600 bg-red-200 rounded-lg">
            <HiTrash className="w-5 h-5" />
            <span className="sr-only">delete icon</span>
          </div>
          <div className="ms-3 text-sm font-normal">
            <span className="mb-1 text-sm font-semibold text-black">{t("removeorder")}</span>
            <div className="mb-2 text-sm font-normal">{t("removeorder-1")}</div>
            <div className="grid grid-cols-2 gap-2">
              <div>
                <button onClick={() => {
                  remove();
                  toast.dismiss(toa.id);
                }} className="inline-flex justify-center w-full px-2 py-1.5 text-xs font-medium text-center text-white bg-red-600 rounded-lg hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300">{t("yes")}</button>
              </div>
              <div>
                <button onClick={() => toast.dismiss(toa.id)} className="inline-flex justify-center w-full px-2 py-1.5 text-xs font-medium text-center text-black bg-white border border-gray-300 rounded-lg hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200">{t("cancel")}</button>
              </div>
            </div>
          </div>
          <button onClick={() => toast.dismiss(toa.id)} className="h-6 w-6 ms-auto -mx-1.5 -my-1.5 bg-white items-center justify-center flex-shrink-0 text-gray-400 hover:text-black rounded-lg focus:ring-2 focus:ring-gray-300 hover:bg-gray-100 inline-flex">
            <span className="sr-only">Close</span>
            <FaXmark className="w-4 h-4" />
          </button>
        </div>
      </div>),
      {
        style: { background: 'none', boxShadow: 'none' },
        duration: 5000,
        position: 'top-center',
      });
  }

  return (
    <>
      {
        xMark ?
          (
            <button className='p-0.5 rounded-md text-red-600 hover:bg-gray-200 hover:text-red-800' onClick={confirmRemove}>
              <FaXmark className='w-4 h-4 ' />
            </button>
          )
          : (
            <button className='p-1 mt-1 rounded-md text-red-600 hover:bg-gray-200 hover:text-red-800' onClick={confirmRemove}>
              <HiTrash className='w-[18px] h-[18px] ' />
            </button>
          )
      }
    </>
  )
}
