import { toast } from 'react-hot-toast';
import { Toast, ToastToggle } from 'flowbite-react';
import { HiCheck, HiExclamation, HiX } from 'react-icons/hi';

const OwnToast = (type, message, duration = 2000, size) => {
  const icons = {
    success: <HiCheck className="h-5 w-5 text-green-700" />,
    error: <HiX className="h-5 w-5 text-red-700" />,
    warning: <HiExclamation className="h-5 w-5 text-yellow-700" />,
  };

  const toastMessage = (
    <Toast style={{ minWidth: size ? '500px' : null }}>
      <div className={`inline-flex h-8 w-8 shrink-0 items-center justify-center rounded-lg ${type === 'success' ? "bg-green-200" : type === "warning" ? "bg-yellow-200" : "bg-red-200"}`}>
        {icons[type]}
      </div>
      <div className="ml-3 mr-2 text-sm font-normal">{message}</div>
      <ToastToggle />
    </Toast>
  );

  // Use toast with your custom message directly
  toast(toastMessage, {
    style: { background: 'none', boxShadow: 'none' },
    duration: duration,
    position: 'top-center',
  });
};

export default OwnToast;
