import { Dropdown } from 'flowbite-react';
import { useState, useEffect } from 'react';
import { GrLanguage } from "react-icons/gr";
import OwnToast from './OwnToast';
import i18n from '../i18n';
import { franceIcon, ukIcon } from '../assets/icons';
import { PiGlobeLight } from "react-icons/pi";

export default function ChangeLang({ typeOfCompo }) {
  let fr_to_en = "Language set to English";
  let en_to_fr = "Langue définie sur le Français";

  const [currentLang, setCurrentLang] = useState(() => {
    return localStorage.getItem("language") || "en";
  });

  const languages = [
    {
      slung: "en",
      title: "English",
      icon: ukIcon
    },
    {
      slung: "fr",
      title: "Français",
      icon: franceIcon
    }
  ];

  useEffect(() => {
    i18n.changeLanguage(currentLang);
  }, [currentLang]);

  // Switching Languages :
  const switchLanguage = (lang) => {
    i18n.changeLanguage(lang);
    setCurrentLang(lang);
    localStorage.setItem("language", lang);
    OwnToast('success', lang === "fr" ? en_to_fr : fr_to_en);
  };


  if (typeOfCompo === "dropdown") {
    return (
      <Dropdown className="text-sm leading-6" label="" dismissOnClick={true} renderTrigger={() => (
        <button className='flex flex-wrap items-center gap-1 px-3 py-1.5'>
          <img src={currentLang === "en" ? ukIcon : franceIcon} className='w-6 h-auto' alt='' /> {currentLang === "en" ? "English" : "Français"}
        </button>
      )}>
        {
          languages.map((item, index) => (
            <Dropdown.Item key={index} onClick={() => switchLanguage(item.slung)} className='flex flex-wrap items-center gap-1'>
              <img src={item.icon} className='w-6 h-auto' alt='' /> {item.title}
            </Dropdown.Item>
          ))
        }
      </Dropdown>
    )
  } else if (typeOfCompo === "sidebar") {
    return (
      <Dropdown className="text-sm leading-6 bg-white" label="" dismissOnClick={true} renderTrigger={() => (
        <button className="bg-white flex items-center leading-none gap-x-3.5 py-2 px-2.5 text-sm text-gray-800 rounded-lg hover:bg-gray-100 focus:outline-none focus:bg-gray-100">
          <PiGlobeLight className="shrink-0 size-4 w-[20px] h-[20px]" />
          {currentLang === "en" ? "English" : "Français"}
        </button>
      )}>
        {
          languages.map((item, index) => (
            <Dropdown.Item key={index} onClick={() => switchLanguage(item.slung)} className='flex flex-wrap items-center gap-1'>
              <img src={item.icon} className='w-6 h-auto' alt='' /> {item.title}
            </Dropdown.Item>
          ))
        }
      </Dropdown>
    )
  } else {
    return (
      <Dropdown className="text-sm leading-6 bg-white" label="" dismissOnClick={true} renderTrigger={() => (
        <button className={`${typeOfCompo === "globeForDash" ? "w-max fixed bottom-4 left-4 max-sm:bottom-3 max-sm:left-2" : "fixed bottom-4 left-4 max-md:bottom-16 md:hidden"} z-20 text-white bg-blue-600 hover:bg-blue-700 p-1 rounded-full`}>
          <GrLanguage className={typeOfCompo === "globeForDash" ? "w-5 h-5" : "w-6 h-6"} />
        </button>
      )}>
        {
          languages.map((item, index) => (
            <Dropdown.Item key={index} onClick={() => switchLanguage(item.slung)} className='flex flex-wrap items-center gap-1'>
              <img src={item.icon} className='w-6 h-auto' alt='' /> {item.title}
            </Dropdown.Item>
          ))
        }
      </Dropdown>
    )
  }
}
