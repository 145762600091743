import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { HiStar } from "react-icons/hi2";
import { BsSearch } from "react-icons/bs";
import { useRefresh } from '../../general additions/RefreshProvider';
import { LuArrowDownUp } from "react-icons/lu";
import { LoadingShopPart } from '../../general additions/LoadingElements';
import { Tooltip } from 'flowbite-react';
import i18n from '../../i18n';
import { FormatDateTable } from '../../elements/FormatDate';

export default function OrdersList() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const localhost = process.env.REACT_APP_LOCALHOST;
  const token = localStorage.getItem("token");
  const { refreshKey } = useRefresh();
  const [orders, setOrders] = useState([]);
  const [category, setCategory] = useState([]);
  const [search, setSearch] = useState("");
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [sortDirection, setSortDirection] = useState({});

  useEffect(() => {
    const fetchOrders = async () => {
      const res = await axios.get(`${localhost}/ordersforadmin`, { headers: { Authorization: `Bearer ${token}` } });
      setOrders(res.data);
      setFilteredOrders(res.data);
    };

    const fetchCategories = async () => {
      const response = await axios.get(`${localhost}/allcategories`);
      if (response) {
        setCategory(response.data);
        setLoading(false);
      }
    };

    fetchOrders();
    fetchCategories();
  }, [refreshKey]);

  const handleSearch = (e) => {
    e.preventDefault();
    const lowercasedSearch = search.toLowerCase();
    const filtered = orders.filter((order) =>
      JSON.parse(order.name)[i18n.language].toLowerCase().includes(lowercasedSearch)
    );
    setFilteredOrders(filtered);
  };

  const handleSort = (key) => {
    const direction = sortDirection[key] === "asc" ? "desc" : "asc";
    setSortDirection({ [key]: direction });

    const sorted = [...filteredOrders].sort((a, b) => {
      const aValue = key === "name" ? JSON.parse(a[key])[i18n.language] : a[key];
      const bValue = key === "name" ? JSON.parse(b[key])[i18n.language] : b[key];

      if (aValue < bValue) return direction === "asc" ? -1 : 1;
      if (aValue > bValue) return direction === "asc" ? 1 : -1;
      return 0;
    });

    setFilteredOrders(sorted);
  };

  return (
    <div className="antialiased">
      <div className="flex justify-start gap-2 items-center max-sm:p-1.5 mb-3 max-sm:mb-0">
        <p className="text-black font-semibold text-xl max-sm:text-lg">{t("orders")}</p>
      </div>

      <div className="">
        <div className="rounded-lg bg-white mt-3 border shadow-sm p-3 flex justify-between max-sm:flex-col gap-2 items-center">
          <form onSubmit={handleSearch} className="flex gap-1 w-full">
            <input className="myborder rounded-md text-sm w-full text-black placeholder:text-gray-400 py-1.5 px-2"
              placeholder={t("search-input-orders")} type="text" value={search} onChange={(e) => setSearch(e.target.value)} />
            <button type="submit" className="h-[34px] min-w-[34px] flex justify-center items-center rounded-md bg-blue-600 hover:bg-blue-700 text-white">
              <BsSearch className="w-5 h-5" />
            </button>
          </form>
        </div>

        {/* Table */}
        <div className="relative overflow-x-auto rounded-lg bg-white mt-3 border shadow-sm">
          {
            loading ? <div className='flex justify-center items-center h-[50vh] w-full'><LoadingShopPart /></div>
              : <table className="w-full text-sm text-left rtl:text-right text-gray-500">
                <thead className="text-xs text-gray-700 bg-gray-50">
                  <tr>
                    <th className="px-6 py-3">
                      <button onClick={() => handleSort("name")} className="flex items-center">
                        {t("name")} <LuArrowDownUp className="-me-0.5 ms-2 h-auto w-[14px]" />
                      </button>
                    </th>
                    <th className="px-6 py-3">
                      <button onClick={() => handleSort("ratings")} className="flex items-center">
                        {t("ratings")} <LuArrowDownUp className="-me-0.5 ms-2 h-auto w-[14px]" />
                      </button>
                    </th>
                    <th className="px-6 py-3">
                      <button onClick={() => handleSort("total_quantity")} className="flex items-center">
                        {t("quantity")} <LuArrowDownUp className="-me-0.5 ms-2 h-auto w-[14px]" />
                      </button>
                    </th>
                    <th className="px-6 py-3">
                      <button onClick={() => handleSort("total_amount")} className="flex items-center">
                        {t("total-amount")} <LuArrowDownUp className="-me-0.5 ms-2 h-auto w-[14px]" />
                      </button>
                    </th>
                    <th className="px-6 py-3">
                      <button onClick={() => handleSort("last_updated")} className="flex items-center">
                        Date <LuArrowDownUp className="-me-0.5 ms-2 h-auto w-[14px]" />
                      </button>
                    </th>
                    <th className="px-6 py-3">
                      {t("view")}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {
                    filteredOrders.map((item, index) => {
                      let productName = JSON.parse(item.name);

                      return (
                        <tr key={index} className="bg-white border-b">
                          <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                            <Tooltip content={productName[i18n.language]}>
                              <a href={"/product/" + item.slug} className='font-semibold cursor-pointer hover:text-blue-600 hover:underline'>
                                {productName[i18n.language].length > 20 ? productName[i18n.language].substring(0, 20) + "..." : productName[i18n.language]}
                              </a>
                            </Tooltip>
                          </th>
                          <td className="px-6 py-4">
                            <p className="flex flex-wrap gap-1 items-start">
                              {item.ratings.toFixed(1)}
                              <HiStar className="w-[17px] h-[17px] text-yellow-300" />
                            </p>
                          </td>
                          <td className="px-6 py-4">{parseInt(item.total_quantity)}</td>
                          <td className="px-6 py-4">{item.total_amount.toFixed(2)}</td>
                          <td className="px-6 py-4">
                            <FormatDateTable dateString={item.last_updated} />
                          </td>
                          <td className="px-6 py-4">
                            <button className='myborder px-2 py-1 text-black text-sm rounded-md hover:bg-gray-100'>{t("view")}</button>
                          </td>
                        </tr>
                      );
                    })
                  }
                </tbody>
              </table>
          }
        </div>
      </div>
    </div>
  );
}
