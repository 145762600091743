import { Modal } from "flowbite-react";
import React, { useState, useRef } from "react";
import { couponVide } from "../../assets/images";
import { useTranslation } from "react-i18next";
import CopyBtn from "./CopyBtn";

const CouponDiv = ({ percentage, code, validUntil }) => {
  const canvasRef = useRef(null);

  const drawImage = () => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    const image = new Image();

    image.src = couponVide;

    image.onload = () => {
      canvas.width = image.width;
      canvas.height = image.height;
      ctx.drawImage(image, 0, 0);

      // Add percentage text
      ctx.font = "600 100px 'League Spartan', sans-serif";
      ctx.fillStyle = "#1e429f";
      ctx.textAlign = "center";
      ctx.fillText(`${percentage} OFF`, canvas.width / 2, 430);

      // Add coupon code
      ctx.font = "600 40px 'League Spartan', sans-serif";
      ctx.fillText(`${code}`, canvas.width / 2, 576);

      // Add valid until text
      ctx.fillStyle = "#fff";
      ctx.font = "600 20px 'League Spartan', sans-serif";
      ctx.fillText(`Valid Until: ${validUntil}`, 175, 718);
    };
  };

  React.useEffect(() => {
    drawImage();
  }, [percentage, code, validUntil]);

  return (
    <div style={{ textAlign: "center" }}>
      <canvas ref={canvasRef} style={{ maxWidth: "100%" }} />
    </div>
  );
};

const CouponCard = ({ discountValue, codeValue, endDateValue }) => {
  const { t } = useTranslation();
  const [openModal, setOpenModal] = useState(false);

  // Move handleDownload here
  const handleDownload = () => {
    const canvas = document.querySelector('canvas');
    const link = document.createElement("a");
    link.download = "coupon.png";
    link.href = canvas.toDataURL("image/png");
    link.click();
  };

  return (
    <div>
      <button onClick={() => setOpenModal(true)} className="myborder px-2 py-1.5 font-semibold text-black text-sm rounded-md hover:bg-gray-100">
        {t("view")}
      </button>
      <Modal show={openModal} onClose={() => setOpenModal(false)} size="lg">
        <Modal.Header className="py-2 px-3"></Modal.Header>
        <Modal.Body className="p-4">
          <CouponDiv percentage={discountValue} code={codeValue} validUntil={endDateValue} />
        </Modal.Body>
        <Modal.Footer className="py-3 px-4 flex justify-center">
          <button onClick={handleDownload} className="myborder px-3 py-2 font-semibold text-blue-700 text-sm rounded-md hover:bg-blue-100">
            {t("download-coupon")}
          </button>
          <CopyBtn value={codeValue} />
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default CouponCard;
