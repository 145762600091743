import i18n from 'i18next';
import { HiPencil } from "react-icons/hi";
import { useTranslation } from "react-i18next";
import { blankimage } from '../../assets/images';
import Dproduct from '../Dproduct';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { useRefresh } from '../../general additions/RefreshProvider';

export default function ProdCard({ data }) {
  const { t } = useTranslation();
  const { refreshKey } = useRefresh();
  const localhost = process.env.REACT_APP_LOCALHOST;
  const localhostImage = process.env.REACT_APP_LOCALHOST_IMG;
  const [discount, setDiscount] = useState([]);
  const [discountText, setDiscountText] = useState([]);
  const [exist, setExist] = useState(false);

  useEffect(() => {
    axios.get(`${localhost}/admin/getdiscountsbyid/${data.productID}`)
      .then((res) => {
        setExist(res.data.discountID ? true : false);
        setDiscount(res.data);
        setDiscountText(res.data.percentage ? parseInt(res.data.percentage) + '%' : parseFloat(res.data.amount) + '$');
      }).catch((err) => console.error(err));
  }, [refreshKey]);

  if (!data) {
    return <p className="text-base text-red-500">{t("error-displaying-infos")}</p>;
  }

  return (
    <div className="bg-white shadow-sm rounded-lg border p-2">
      <div className="relative rounded-md overflow-hidden h-auto max-w-md mx-auto">
        <a href={"/product/" + data.slug}>
          <img className={`object-fill object-center ${data.stock > 0 ? "" : "grayscale"}`} src={data.image ? localhostImage + data.image : blankimage} alt={data.name[i18n.language]} />
          {exist ? <span className={`absolute top-2 right-2 bg-blue-600 text-white text-xs px-2 py-1 rounded-full`}>
            -{discountText}
          </span> : null}
        </a>
      </div>

      <div className="p-1 mt-1">
        <a href={"/product/" + data.slug} className="text-base font-semibold leading-tight text-black hover:text-blue-700">
          {data.name[i18n.language].length > 45 ? data.name[i18n.language].substring(0, 45) + "..." : data.name[i18n.language]}
        </a>

        <div className='flex flex-wrap items-end gap-1 mt-1'>
          <p className="text-xl mt-1 font-extrabold leading-none text-black">${exist ? discount.newPrice.toFixed(2) : data.price.toFixed(2)}</p>
          {exist ? <span className="text-red-700 line-through text-xs font-semibold">${data.price.toFixed(2)}</span> : null}
        </div>

        <div className="mt-4 flex text-sm items-center justify-end gap-2 font-semibold">
          <a href={`/admin/dashboard/edit_product/${data.slug}`} className='flex flex-wrap gap-1 px-2 py-1.5 text-black myborder rounded-md hover:bg-gray-100'>
            <HiPencil className='h-5 w-5 text-black' />
            {t("edit")}
          </a>

          <Dproduct prodSlug={data.slug} />
        </div>
      </div>
    </div>
  )
}
