import React from 'react';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';

export default function OrderCard({ data }) {
  const { t } = useTranslation();
  const localhostImage = process.env.REACT_APP_LOCALHOST_IMG;
  const parsedName = JSON.parse(data.name);

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 min-[550px]:gap-6 py-3 border-b border-gray-200 max-sm:max-w-xl max-xl:mx-auto">
      <div className="flex items-center flex-col min-[550px]:flex-row gap-3 min-[550px]:gap-4 w-full max-sm:justify-center max-sm:max-w-xl max-xl:mx-auto">
        <div className="img-box bg-gray-50 rounded-xl justify-center items-center inline-flex">
          <img src={localhostImage + data.image} alt="product" className="md:w-24 object-cover" />
        </div>
        <div className="pro-data w-full max-w-sm flex-col justify-start items-start gap-2 inline-flex">
          <a href={`/product/${data.slug}`} className="w-full text-lg text-black hover:text-blue-700 leading-none max-[550px]:text-center">
            {parsedName[i18n.language]}
          </a>
          <h5 className="w-full text-gray-500 text-sm font-normal leading-relaxed min-[550px]:my-0 my-2 max-[550px]:text-center">
            {t("product")} ID {data.uniqueProd}
          </h5>
        </div>
      </div>

      <div className="w-full flex items-center justify-between flex-col min-[550px]:flex-row max-sm:max-w-xl max-xl:mx-auto gap-2 text-black text-sm">
        <div className='flex items-end'>
          <span className='text-gray-400 w-max text-base'>{data.total_quantity ? data.total_quantity : data.quantity} x</span>
          <div className='flex justify-end items-end sm:flex-col'>
            {data.priceAfterDiscount ? <span className="text-red-700 line-through text-xs max-sm:text-sm font-medium max-sm:order-2 max-sm:mb-1 max-sm:ml-1">${parseFloat(data.price).toFixed(2)}</span> : null}
            <span className='text-xl w-max ml-1 max-sm:order-1'>${data.priceAfterDiscount ? parseFloat(data.priceAfterDiscount).toFixed(2) : parseFloat(data.price).toFixed(2)}</span>
          </div>
        </div>
        <h5 className="w-full text-center font-semibold sm:flex sm:justify-end text-green-500 text-xl leading-relaxed">
          = ${data.total_amount}
        </h5>
      </div>

    </div>
  );
}
