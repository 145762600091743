import React from 'react'
import Header from '../additions/Header'
import Footer from '../additions/Footer'
import Details from './Details'
import TopBar from '../additions/TopBar'

export default function DetailsProduct() {
  return (
    <section>
      <TopBar/>
      <Header />
      <div className='bg-gray-50'>
        <div className='container'>
          <Details />
        </div>
      </div>
      <Footer />
    </section>
  )
}
