import { Drawer } from "flowbite-react";
import { useEffect, useState } from "react";
import { FaCartShopping } from "react-icons/fa6";
import axios from "axios";
import i18n from 'i18next';
import { useTranslation } from "react-i18next";
import RemoveOrder from "./RemoveOrder";
import { FormatDateForcomment } from "./FormatDate";
import { LoadingShopPart } from "../general additions/LoadingElements";
import { useRefresh } from "../general additions/RefreshProvider";
import CheckouBtn from "./CheckouBtn";

export default function ShoppingCart() {
  const { t } = useTranslation();
  const localhost = process.env.REACT_APP_LOCALHOST;
  const localhostImage = process.env.REACT_APP_LOCALHOST_IMG;
  const uniqueID = localStorage.getItem("uniqueID");
  const token = localStorage.getItem("token");
  const [loading, setLoading] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [orders, setOrders] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [refresh, setRefresh] = useState(false);
  const { refreshKey } = useRefresh();

  const handleClose = () => setIsOpen(false);

  useEffect(() => {
    const cart = JSON.parse(localStorage.getItem('shoppingCart')) || [];

    const fetchProductData = async (slug) => {
      try {
        const response = await axios.get(`${localhost}/product/${slug}`);
        return response.data;
      } catch (error) {
        console.error('Error fetching product data:', error);
        return null;
      }
    };

    const fetchCartDetails = async () => {
      let newOrders = [];
      let totalCartPrice = 0;

      for (const item of cart) {
        const productData = await fetchProductData(item.product_slug);

        if (productData) {
          const price = isNaN(parseFloat(productData[0].price)) ? 0 : parseFloat(productData[0].price);
          const quantity = isNaN(parseInt(item.quantity)) ? 0 : parseInt(item.quantity);
          const totalProductPrice = price * quantity;

          newOrders.push({
            ...productData[0],
            quantity,
            totalPrice: totalProductPrice.toFixed(2),
          });

          totalCartPrice += parseFloat(totalProductPrice);
        }
      }
      setOrders(newOrders);
      setTotalPrice(totalCartPrice.toFixed(2));
      setLoading(false);
    };

    if (token) {
      axios.get(localhost + "/orders/" + uniqueID, { headers: { 'Authorization': `Bearer ${token}` } })
        .then((res) => {
          setOrders(res.data);
          setLoading(false);
        })
        .catch((err) => console.error(err));

    } else {
      fetchCartDetails();
    }
  }, [refresh, refreshKey]);

  const handleRefresh = () => {
    setRefresh(prev => !prev);
  };

  if (!orders) {
    return <p className="text-base text-black"><LoadingShopPart /> </p>;
  }

  // Subtotal calculation based on individual product prices and quantities
  const calculateSubtotal = () => {
    const total = orders.reduce((total, item) => total + parseFloat(item.total_amount), 0).toFixed(2);
    return total !== "NaN" ? total : totalPrice;
  };

  const calculateOriginalSubtotal = () => {
    const total = orders.reduce((total, item) => total + parseFloat(item.total_original_amount), 0).toFixed(2);
    return total !== "NaN" ? total : totalPrice;
  };

  return (
    <>
      <div className="relative inline-flex">
        <button onClick={() => setIsOpen(true)} className="flex items-center justify-center rounded-md h-9 w-9 max-md:w-8 max-md:h-8 bg-blue-600 focus:bg-blue-700 text-white transition-all">
          <FaCartShopping className="w-[18px] h-[18px]" />
        </button>
        <span className="absolute top-0.5 right-0.5 grid h-5 min-w-5 max-md:min-w-[18px] max-md:h-[18px] translate-x-2/4 -translate-y-2/4 place-items-center rounded-full bg-red-500 py-1 px-1 text-xs font-medium leading-none text-white content-['']">
          {orders.length}
        </span>
      </div>

      <Drawer className="w-1/3 max-xl:w-[40%] max-md:w-full" open={isOpen} onClose={handleClose} position="right">
        <Drawer.Header title={t("shop-cart")} titleIcon={() => <FaCartShopping className="h-5 w-5 mr-1" />} />
        <Drawer.Items>
          {
            loading ?
              (<div className="flex justify-center items-center w-full min-h-[82vh]">
                <LoadingShopPart />
              </div>)
              : (<ul className='border-y border-gray-200 overflow-y-auto h-[82vh]'>
                {orders.map((item, index) => {
                  let productName;

                  try {
                    productName = JSON.parse(item.name);
                  } catch (error) {
                    console.error("Error parsing JSON data: ", error);
                    return <p className="text-base text-red-500">{t("error-displaying-infos")}</p>;
                  }

                  return (
                    <li key={index} className='flex flex-row items-center gap-3 px-3 py-4 border-b border-gray-200'>
                      <img className='w-auto h-24' src={localhostImage + item.image} alt={"product " + index} />
                      <div className='w-full'>
                        <h2 className="capitalize text-lg text-black font-semibold">{productName[i18n.language]}</h2>
                        <p className="text-xs"><FormatDateForcomment dateString={item.updated_at} /></p>

                        <div className='flex items-center justify-between mt-2'>
                          <p className='text-sm mt-1 flex flex-wrap font-semibold leading-3'>
                            <span className='text-gray-400 mr-1 font-normal'>{item.total_quantity ? item.total_quantity : item.quantity} x</span> ${item.priceAfterDiscount ? parseFloat(item.priceAfterDiscount).toFixed(2) : parseFloat(item.price).toFixed(2)}
                            {item.priceAfterDiscount ? <span className="ml-1 text-red-700 line-through text-xs font-medium">${parseFloat(item.price).toFixed(2)}</span> : null}
                          </p>
                          <RemoveOrder prod_id={item.productID} onRemove={handleRefresh} />
                        </div>
                      </div>
                    </li>
                  );
                })}
              </ul>)
          }

          <div className='p-2'>
            <div className='flex justify-between items-end gap-1 my-2 font-semibold text-lg text-black'>
              <span>{t("subtotal")} :</span>
              <span>{calculateSubtotal() === calculateOriginalSubtotal() ? <span>${calculateSubtotal()}</span>
                : <div className="flex wrap gap-1 leading-3">${calculateSubtotal()}<span className="text-red-700 line-through text-xs font-semibold">${calculateOriginalSubtotal()}</span></div>}</span>
            </div>

            <CheckouBtn activeBtn={!orders.length > 0} />
          </div>
        </Drawer.Items>
      </Drawer>
    </>
  );
}
