import React, { useEffect, useState } from 'react'
import { BsSearch } from "react-icons/bs";
import { HiStar } from "react-icons/hi2";
import { useTranslation } from 'react-i18next';
import { useRefresh } from '../../general additions/RefreshProvider';
import axios from 'axios';
import { LoadingShopPart } from '../../general additions/LoadingElements';
import { notresultsfound } from '../../assets/images';
import AddDiscount from '../admin elements/AddDiscount';
import { Tooltip } from 'flowbite-react';
import i18n from '../../i18n';
import GetDiscountsById from '../admin elements/GetDiscountsById';

export default function DiscountsList() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const localhost = process.env.REACT_APP_LOCALHOST;
  const token = localStorage.getItem("token");
  const { refreshKey } = useRefresh();
  const [offers, setOffers] = useState([]);
  const [search, setSearch] = useState("");
  const [filteredOffers, setFilteredOffers] = useState([]);

  // Fetch offers data
  useEffect(() => {
    axios.get(`${localhost}/admin/getallproductsdiscounts`, { headers: { Authorization: `Bearer ${token}` } })
      .then((res) => {
        setOffers(res.data);
        setFilteredOffers(res.data);
        setLoading(false);
      })
      .catch((err) => console.error(err));
  }, [refreshKey]);

  // Handle search
  const handleSearch = (e) => {
    e.preventDefault();
    const lowercasedSearch = search.toLowerCase();
    const filtered = offers.filter((product) =>
      product.name?.toLowerCase().includes(lowercasedSearch)
    );
    setFilteredOffers(filtered);
  };

  return (
    <div className="antialiased">
      <div className="flex justify-between gap-2 items-center max-sm:p-1.5 mb-3 max-sm:mb-0">
        <p className="text-black font-semibold text-xl max-sm:text-lg">{t("special-discounts")}</p>
      </div>

      <div className="">
        <div className="rounded-lg bg-white mt-3 border shadow-sm p-3 flex justify-between max-sm:flex-col gap-2 items-center">
          <form onSubmit={handleSearch} className="flex gap-1 w-full">
            <input className="myborder rounded-md text-sm w-full text-black placeholder:text-gray-400 py-1.5 px-2"
              placeholder={t("search-input-discounts")} type="text" value={search} onChange={(e) => setSearch(e.target.value)} />
            <button type="submit" className="h-[34px] min-w-[34px] flex justify-center items-center rounded-md bg-blue-600 hover:bg-blue-700 text-white">
              <BsSearch className="w-5 h-5" />
            </button>
          </form>
        </div>

        {/* Table */}
        <div className="relative overflow-x-auto rounded-lg bg-white mt-3 border shadow-sm">
          {loading ? (
            <div className='flex justify-center items-center h-[50vh] w-full'>
              <LoadingShopPart />
            </div>
          ) : filteredOffers.length > 0 ? (
            <table className="w-full text-sm text-left rtl:text-right text-gray-500">
              <thead className="text-xs text-gray-700 bg-gray-50">
                <tr>
                  <th className="px-6 py-3">{t("product")} ID</th>
                  <th className="px-6 py-3">{t("name")}</th>
                  <th className="px-6 py-3">Stock</th>
                  <th className="px-6 py-3">{t("price")}</th>
                  <th className="px-6 py-3">{t("ratings")}</th>
                  <th className="px-6 py-3">Actions</th>
                </tr>
              </thead>
              <tbody>
                {filteredOffers.map((item, index) => {
                  let productName = JSON.parse(item.name);

                  return (
                    <tr key={index} className="bg-white border-b">
                      <th scope="row" className="px-6 py-4 font-semibold text-gray-900 whitespace-nowrap">
                        {item.uniqueProd}
                      </th>
                      <td className="px-6 py-4">
                        <Tooltip content={productName[i18n.language]}>
                          <a href={"/product/" + item.slug} className='font-medium cursor-pointer hover:text-blue-600 hover:underline'>
                            {productName[i18n.language].length > 20 ? productName[i18n.language].substring(0, 20) + "..." : productName[i18n.language]}
                          </a>
                        </Tooltip>
                      </td>
                      <td className="px-6 py-4">{item.stock}</td>
                      <td className="px-6 py-4">{item.price}</td>
                      <td className="px-6 py-4">
                        <p className='flex flex-wrap gap-1 items-start'>{item.ratings} <HiStar className='w-[17px] h-[17px] text-yellow-300' /></p>
                      </td>
                      <td className="flex flex-wrap gap-2 px-6 py-4">
                        <AddDiscount productID={item.productID} />
                        {
                          item.product_id === null ? <button disabled className='myborder disabled:text-gray-400 disabled:hover:bg-white disabled:cursor-not-allowed px-2 py-1.5 text-black text-sm rounded-md hover:bg-gray-100'>
                            {t("see-discounts")}
                          </button>
                            : <GetDiscountsById productId={item.productID} />
                        }
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          ) : (
            <div className="flex justify-center items-center py-10 text-gray-500">
              <div className="col-span-4 text-center text-black flex flex-col gap-6 justify-center items-center min-h-[40vh]">
                <img src={notresultsfound} className="w-48 h-auto" />
                {t("no-results")}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
