import React from 'react'
import Header from '../additions/Header'
import Footer from '../additions/Footer'
import { aboutImg1 } from '../../assets/images'
import TopBar from '../additions/TopBar'

export default function AboutUs() {
  return (
    <section>
      <TopBar/>
      <Header />
      <div className='bg-gray-50'>
        <div className='container'>
          <div className="py-6 relative z-0">
            <h1 className="max-w-2xl mx-auto text-center font-manrope font-bold text-4xl text-black mb-8 md:text-5xl md:leading-normal">
              About Us
            </h1>

            <div className="mb-8 rounded-full">
              <h2 className="text-3xl font-manrope font-bold text-blue-700 text-center">We aim to Empower every Business <br /> and Individual to achieve more.</h2>
            </div>
            <div className="max-w-max mx-auto lg:max-w-4xl">
              <p className="text-lg text-gray-500 leading-8 mb-8 text-center">
                All Good Keys is a platform that provides buyers from all around the world with the possibility to buy software keys at a fair price with no hassle through an innovative platform. <br /> <br />
                We are the best way to buy, install and use software! With All Good Keys Software you won’t just get an installation package and a wish good luck. With us you’ll be sure that the license is legitimate and the software will surely work to your liking. <br /> <br />
                Our software are 100% legitimate and can be installed safely and reliably. We deliver the software and assist you during the installation process from start to finish. We won’t stop until you’re fully satisfied!
              </p>
            </div>
          </div>

          <div className="relative xl:mr-0 lg:mr-5 mr-0">
            <div className="w-full max-w-7xl mx-auto">
              <div className="w-full justify-start items-center gap-5 grid lg:grid-cols-2 grid-cols-1">
                <div className="w-full flex-col justify-center lg:items-start items-center gap-10 inline-flex">
                  <div className="w-full flex-col justify-center items-start gap-8 flex">
                    <div className="w-full flex-col justify-center items-start gap-6 flex">
                      <div className="w-full justify-start items-center gap-8 grid md:grid-cols-2 grid-cols-1">
                        <div
                          className="w-full h-full p-3.5 rounded-xl border border-gray-200 hover:border-gray-400 transition-all duration-700 ease-in-out flex-col justify-start items-start gap-2.5 inline-flex">
                          <h4 className="text-black text-2xl font-bold font-manrope leading-9">+23K</h4>
                          <p className="text-gray-500 text-base font-semibold leading-relaxed">Customers served</p>
                        </div>
                        <div
                          className="w-full h-full p-3.5 rounded-xl border border-gray-200 hover:border-gray-400 transition-all duration-700 ease-in-out flex-col justify-start items-start gap-2.5 inline-flex">
                          <h4 className="text-black text-2xl font-bold font-manrope leading-9">+9.6/10</h4>
                          <p className="text-gray-500 text-base leading-relaxed"><span className='font-semibold'>Our trustscore</span> People love us</p>
                        </div>
                      </div>
                      <div className="w-full h-full justify-start items-center gap-8 grid md:grid-cols-2 grid-cols-1">
                        <div
                          className="w-full p-3.5 rounded-xl border border-gray-200 hover:border-gray-400 transition-all duration-700 ease-in-out flex-col justify-start items-start gap-2.5 inline-flex">
                          <h4 className="text-black text-2xl font-bold font-manrope leading-9">+1000</h4>
                          <p className="text-gray-500 text-base leading-relaxed"><span className='font-semibold'>Reviews worldwide</span> +96% positive reviews</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="w-full lg:justify-start justify-center items-start flex">
                  <img className="w-full h-auto object-cover"
                    src={aboutImg1} alt="about us" />
                </div>
              </div>
            </div>
          </div>

          <div className="py-10 my-16">
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
              <h2 className="font-manrope text-4xl text-center text-black font-bold mb-14">Our results in numbers</h2>
              <div className="flex flex-col gap-5 xl:gap-8 lg:flex-row lg:justify-between">
                <div
                  className="w-full max-lg:max-w-2xl mx-auto lg:mx-0 lg:w-1/3 bg-white p-6 rounded-2xl shadow-md shadow-gray-100">
                  <div className="flex gap-5">
                    <div className="font-manrope text-2xl font-bold text-gray-600">
                      240%
                    </div>
                    <div className="flex-1">
                      <h4 className="text-xl text-black font-semibold mb-2">Company growth</h4>
                      <p className="text-xs text-gray-500 leading-5">Company's remarkable growth journey as we
                        continually innovate and drive towards new heights of success.</p>
                    </div>
                  </div>
                </div>
                <div
                  className="w-full max-lg:max-w-2xl mx-auto lg:mx-0 lg:w-1/3 bg-white p-6 rounded-2xl shadow-md shadow-gray-100">
                  <div className="flex gap-5">
                    <div className="font-manrope text-2xl font-bold text-gray-600">
                      175+
                    </div>
                    <div className="flex-1">
                      <h4 className="text-xl text-black font-semibold mb-2">Company growth</h4>
                      <p className="text-xs text-gray-500 leading-5">Our very talented team members are the powerhouse
                        of pagedone and pillars of our success. </p>
                    </div>
                  </div>
                </div>
                <div
                  className="w-full max-lg:max-w-2xl mx-auto lg:mx-0 lg:w-1/3 bg-white p-6 rounded-2xl shadow-md shadow-gray-100">
                  <div className="flex gap-5">
                    <div className="font-manrope text-2xl font-bold text-gray-600">
                      625+
                    </div>
                    <div className="flex-1">
                      <h4 className="text-xl text-black font-semibold mb-2">Projects Completed</h4>
                      <p className="text-xs text-gray-500 leading-5">We have accomplished more than 625 projects
                        worldwide and we are still counting many more.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <div className="py-20 ">
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
              <div className="bg-gray-600 rounded-2xl p-8 xl:p-11">
                <h2 className="font-manrope text-4xl text-white text-center font-bold mb-4">Subscribe to the latest offer
                </h2>
                <p className="text-gray-200 text-center mb-11 max-lg:max-w-2xl mx-auto">Join our community of subscribers
                  and receive regular
                  updates delivered straight to your inbox. It's quick, easy, and free</p>
                <div
                  className="max-w-md mx-auto lg:bg-transparent lg:border border-gray-300 rounded-3xl max-lg:py-3 lg:rounded-full lg:h-12 lg:p-1.5 lg:flex-row gap-6 lg:gap-0 flex-col flex items-center justify-between">
                  <input type="text" name="email"
                    className="py-2 px-6 bg-transparent rounded-full max-lg:border border-gray-300  text-gray-100 max-lg:text-center placeholder:text-gray-400 focus:outline-none flex-1 w-full lg:w-auto lg:py-2 lg:px-6 lg:bg-transparent"
                    placeholder="Enter your email.." />
                  <button type="submit"
                    className="py-2 px-5 text-sm bg-gray-500 shadow-md rounded-full  text-white font-semibold hover:bg-gray-700">Subscribe</button>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
      <Footer />
    </section >
  )
}
