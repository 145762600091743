import axios from "axios";
import { Spinner } from "flowbite-react";
import i18next from "i18next";
import { useEffect, useState } from "react";

export const getAllLicenses = async () => {
  const localhost = process.env.REACT_APP_LOCALHOST;

  try {
    const response = await axios.get(`${localhost}/alllicenses`);
    return response.data;
  } catch (error) {
    console.error('Error fetching license info:', error);
    return null;
  }
};

export const GetLicense = ({ license_id }) => {
  const localhost = process.env.REACT_APP_LOCALHOST;
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);

  useEffect(() => {
    if (!license_id) {
      setLoading(false);
      return;
    }

    const fetchData = async () => {
      try {
        const response = await axios.get(`${localhost}/alllicenses/${license_id}`);
        setData(response.data);
        setLoading(false);
      } catch (err) {
        setError("Error!");
        console.error(err);
      };
    };

    fetchData();
  }, [license_id, localhost]);

  if (loading) {
    return <div><Spinner className="fill-black text-gray-300 w-4 h-4" /></div>;
  }

  if (error) {
    return <div className="text-red-600">Error</div>;
  }

  return data.title[i18next.language];
};
