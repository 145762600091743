import { HiXMark } from "react-icons/hi2";

export default function OrderConfiramtion() {
  return (
    <section className="py-24 relative">
      <div className="w-full max-w-7xl px-4 md:px-5 lg:px-5 mx-auto">
        <div className="w-full flex-col justify-start items-start gap-8 inline-flex">
          <div className="w-full flex-col justify-start items-start lg:gap-14 gap-8 flex">
            <div className="w-full text-center text-black text-3xl font-bold font-manrope leading-normal">Order  Confirmation</div>
            <div className="flex-col justify-start items-start gap-3 flex">
              <h4 className="text-black text-xl font-medium leading-8">Hello, John smith</h4>
              <h5 className="text-gray-500 text-lg font-normal leading-8">Thank you for shopping</h5>
              <h5 className="text-gray-500 text-lg font-normal leading-8">Your order has been confirmed and will be shipped within two days.</h5>
            </div>
          </div>
          <div className="w-full justify-center items-start ">
            {
              [1, 2, 3, 4, 5].map((item, index) => (
                <div key={index} className="grid grid-cols-1 md:grid-cols-2 min-[550px]:gap-6 py-3 border-b border-gray-200 max-sm:max-w-xl max-xl:mx-auto">
                  <div className="flex items-center flex-col min-[550px]:flex-row gap-3 min-[550px]:gap-4 w-full max-sm:justify-center max-sm:max-w-xl max-xl:mx-auto">
                    <div className=" img-box bg-gray-50 rounded-xl justify-center items-center inline-flex">
                      <img src="https://allgoodkeys.com/wp-content/uploads/2021/10/Office-2021-Professional-plus-2-626x748.png" alt="Denim Shirt" className="xl:w-[75px] object-cover" />
                    </div>
                    <div className="pro-data w-full max-w-sm flex-col justify-start items-start gap-2 inline-flex">
                      <h4 className="w-full text-black text-lg font-medium leading-8 max-[550px]:text-center">Office 2021 Professional Plus Activation Key – (PC)</h4>
                      <h5 className="w-full text-gray-500 text-base font-normal leading-relaxed min-[550px]:my-0 my-2 max-[550px]:text-center">Product ID: 4553458120</h5>
                    </div>
                  </div>

                  <div className="w-full flex items-center justify-between flex-col min-[550px]:flex-row max-sm:max-w-xl max-xl:mx-auto gap-2">
                    <h5 className="max-w-[142px] w-full text-center text-black text-lg font-medium leading-relaxed pl-5">Quantity : 02</h5>
                    <h5 className="max-w-[142px] w-full text-center text-black text-lg font-medium leading-relaxed pl-5">Price : $50.00</h5>
                    <button className="group w-28 text-center flex flex-wrap text-red-600 hover:text-red-700 items-center transition-all duration-700 ease-in-out">
                      Remove <HiXMark className="w-6 h-6 transition-all duration-700 ease-in-out mx-auto" />
                    </button>
                  </div>
                </div>
              ))
            }

          </div>
          <div className="w-full p-5 rounded-xl border border-gray-200 flex-col justify-start items-center gap-4 flex">
            <div className="w-full justify-between items-center gap-6 inline-flex">
              <h5 className="text-gray-600 text-lg font-normal leading-8">Subtotal:</h5>
              <h5 className="text-right text-black text-lg font-semibold leading-8">$ 100.00</h5>
            </div>
            <div className="w-full justify-between items-center gap-6 inline-flex">
              <h5 className="text-gray-600 text-lg font-normal leading-8">Total:</h5>
              <h5 className="text-right text-black text-lg font-semibold leading-8">₹100.00</h5>
            </div>
          </div>
        </div>
      </div>
    </section>

  )
}
