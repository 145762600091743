import React, { useState } from 'react'
import { banner1, banner2, banner3, banner4 } from '../../assets/images';

export default function Banner() {
  const [activeIndex, setActiveIndex] = useState(null);

  return (
    <div className='mx-auto'>
      <div className="flex flex-row max-md:flex-col md:h-96 overflow-hidden max-md:hidden">
        {/* First Banner */}
        <a href='#_' className={`relative h-auto flex-shrink-0 ${activeIndex === null ? 'w-[76%] max-md:h-56 max-md:w-full' : 'w-[8%] max-md:h-8 max-md:w-full'
          } transition-all duration-300 ease-in-out`}
          onMouseEnter={() => setActiveIndex(null)}>
          <img src={banner4}
            alt="Banner 1"
            className="w-full h-full object-cover" />
        </a>

        {/* Other Banners */}
        {[banner3, banner2, banner1].map((src, index) => (
          <a href='#_'
            key={index}
            className={`relative flex-shrink-0 ${activeIndex === index ? 'w-[76%] max-md:h-56 max-md:w-full' : 'w-[8%] max-md:h-8 max-md:w-full'
              } transition-all duration-300 ease-in-out`}
            onMouseEnter={() => setActiveIndex(index)}
            onMouseLeave={() => setActiveIndex(null)}
          >
            <img src={src} alt={`Banner ${index + 2}`} className="w-full h-full object-cover" />
          </a>
        )
        )}
      </div>

    </div>
  );
}
