import { useNavigate, useParams } from 'react-router';
import React, { useEffect, useState } from 'react';
import AccountDetails from './AccountDetails';
import HistoryOrders from './HistoryOrders';
import { HiCreditCard, HiListBullet, HiUser } from 'react-icons/hi2';
import { FaMoneyBills } from "react-icons/fa6";
import Logout from './Logout';
import PaymentMethods from './PaymentMethods';
import { useTranslation } from 'react-i18next';
import { getUserInfo } from '../../auth/getUserInfo';
import { useRefresh } from '../../general additions/RefreshProvider';
import Footer from '../additions/Footer';
import TopBar from '../additions/TopBar';
import Header from '../additions/Header';
import TransactionsList from './TransactionsList';

const links = ["dashboard", "purchases", "Transactions", "payment", "account-details"];
const menu = [
  {
    title: "purchases",
    icon: <HiListBullet className='inline h-8 w-8 text-gray-500 group-hover:text-blue-600' />
  },
  {
    title: "Transactions",
    icon: <FaMoneyBills className='inline h-8 w-8 text-gray-500 group-hover:text-blue-600' />
  },
  {
    title: "payment",
    icon: <HiCreditCard className='inline h-8 w-8 text-gray-500 group-hover:text-blue-600' />
  },
  {
    title: "account-details",
    icon: <HiUser className='inline h-8 w-8 text-gray-500 group-hover:text-blue-600' />
  }
];

export default function Settings() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { section } = useParams();
  const [menuActive, setMenuActive] = useState(section || "dashboard");
  const { refreshKey } = useRefresh();

  useEffect(() => {
    const fetchUserInfo = async () => {
      const userInfo = await getUserInfo();
      if (userInfo) {
        localStorage.setItem('uniqueID', userInfo.uniqueID);
        localStorage.setItem("username", userInfo.username);
      } else {
        // console.log("User info not found");
      }
    };
    fetchUserInfo();
  }, [refreshKey]);

  const username = localStorage.getItem("username");

  const handleMenuClick = (section) => {
    setMenuActive(section);
    navigate(`/account/${section}`);
  };

  return (
    <section>
      <TopBar />
      <Header />
      <div className='bg-gray-50'>
        <section className='container'>
          <div className="mx-4 min-h-screen max-w-screen-xl sm:mx-8 xl:mx-auto">
            <h1 className="border-b py-6 text-3xl text-black font-semibold">{t("account")}</h1>
            <div className="grid grid-cols-8 pt-3 sm:grid-cols-10">

              {/* Mobile Menu */}
              <div className="relative my-4 w-56 sm:hidden">
                <input className="peer hidden" type="checkbox" name="select-1" id="select-1" />
                <label htmlFor="select-1" className="flex w-full cursor-pointer select-none rounded-lg border p-2 px-3 text-sm text-gray-700 ring-blue-700 peer-checked:ring">
                  {t(menuActive)}
                </label>
                <svg xmlns="http://www.w3.org/2000/svg" className="pointer-events-none absolute right-0 top-3 ml-auto mr-5 h-4 text-slate-700 transition peer-checked:rotate-180" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
                </svg>
                <ul className="max-h-0 flex-col overflow-hidden rounded-b-lg shadow-sm transition-all duration-300 peer-checked:max-h-56 peer-checked:py-3">
                  {links.map((item, index) => (
                    <li key={index} onClick={() => handleMenuClick(item)} className="cursor-pointer px-3 py-2 text-sm text-slate-600 hover:bg-blue-700 hover:text-white">
                      {t(item)}
                    </li>
                  ))}
                </ul>
              </div>

              {/* Desktop Menu */}
              <div className="col-span-2 hidden sm:block">
                <ul>
                  {links.map((item, index) => (
                    <li key={index} onClick={() => handleMenuClick(item)} className={`mt-5 cursor-pointer border-l-2 px-2 py-2 font-semibold transition hover:border-l-blue-700 hover:text-blue-700 ${menuActive === item ? "border-l-blue-700 text-blue-700" : "border-transparent"}`}>
                      {t(item)}
                    </li>
                  ))}
                  <li className="mt-5 cursor-pointer border-l-2 px-2 py-2 font-semibold transition hover:border-l-blue-700 hover:text-blue-700 border-transparent">
                    <Logout compo="Logout" />
                  </li>
                </ul>
              </div>

              {/* Main Content */}
              <div className="col-span-8 pt-6 pb-12 overflow-hidden rounded-xl sm:bg-gray-50 sm:px-8">
                {menuActive === "dashboard" ? (
                  <div>
                    <h1 className="py-2 text-2xl text-black font-semibold">{t("account")}</h1>
                    <p className="text-slate-600">{t("hello")} <strong>{username}</strong></p>
                    <hr className="mt-4 mb-8" />

                    <div className="grid grid-cols-3 gap-4 max-md:grid-cols-2 max-sm:grid-cols-1">
                      {menu.map((item, index) => (
                        <div key={index} onClick={() => handleMenuClick(item.title)} className="group cursor-pointer rounded-lg bg-white p-8 hover:bg-gray-100">
                          <div className="mx-auto mb-2 flex h-[58px] w-[58px] items-center justify-center rounded-full bg-gray-200 group-hover:bg-blue-200 p-2">
                            {item.icon}
                          </div>
                          <div className="text-center font-medium text-gray-500 group-hover:text-blue-600">
                            {t(item.title)}
                          </div>
                        </div>
                      ))}
                      <Logout compo="from-dashboard" />
                    </div>
                  </div>
                ) : menuActive === "purchases" ? <HistoryOrders /> : menuActive === "payment" ? <PaymentMethods /> : menuActive === "Transactions" ? <TransactionsList /> : <AccountDetails />}
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </section>
  );
}
