import React, { useState, useEffect } from 'react';
import { HiStar } from "react-icons/hi2";

export default function RatingStars({ size = 5, rating = 0 }) {
  const [fRating, setFRating] = useState(0);

  useEffect(() => {
    setFRating(Math.round(rating));
  }, [rating]);

  return (
    <div className='flex flex-wrap items-center'>
      {/* Render 5 stars */}
      {[...Array(5)].map((star, index) => {
        const ratingValue = index + 1;

        return (
          <span key={index}>
            {ratingValue <= fRating ? (
              <HiStar className={`w-${size} h-${size} text-yellow-300`} />
            ) : (
              <HiStar className={`w-${size} h-${size} text-gray-400`} />
            )}
          </span>
        );
      })}
    </div>
  );
};
