import React, { useState } from 'react';
import { HiPlus } from "react-icons/hi";
import { Modal } from 'flowbite-react';
import { useTranslation } from 'react-i18next';
import OwnToast from '../../general additions/OwnToast';
import axios from 'axios';
import { useRefresh } from '../../general additions/RefreshProvider';

export default function AddCategory({ IsThisLicense }) {
  const { t } = useTranslation();
  const { triggerRefresh } = useRefresh();
  const token = localStorage.getItem('token');
  const localhost = process.env.REACT_APP_LOCALHOST;
  const [openModal, setOpenModal] = useState(false);

  // Category :
  const [titleEn, setTitleEn] = useState("");
  const [titleFr, setTitleFr] = useState("");
  const [detailsEn, setDetailsEn] = useState("");
  const [detailsFr, setDetailsFr] = useState("");
  const link = IsThisLicense ? '/admin/licenses' : '/admin/categories';
  const type = IsThisLicense ? 'license' : 'category';

  const handleAddCategory = (e) => {
    e.preventDefault();
    const categoryData = {
      title: {
        en: titleEn,
        fr: titleFr
      },
      details: {
        en: detailsEn,
        fr: detailsFr
      },
    };

    axios.post(localhost + link, categoryData, { headers: { 'Authorization': `Bearer ${token}` } })
      .then((res) => {
        OwnToast('success', t("add-" + type + "-success"));
        triggerRefresh();
        setOpenModal(false);
        setTitleEn("");
        setTitleFr("");
        setDetailsEn("");
        setDetailsFr("");
      })
      .catch((err) => {
        OwnToast('error', t("add-" + type + "-error"));
        console.error(err);
      });
  }

  return (
    <>
      <button onClick={() => setOpenModal(true)} className="flex flex-wrap gap-1 items-center text-sm bg-blue-600 hover:bg-blue-700 text-white pl-2 pr-3 py-2 max-sm:p-1.5 rounded-md">
        <HiPlus className="h-[18px] w-[18px]" />
        <span className="max-sm:hidden">{t("add-" + type)}</span>
      </button>

      <Modal size="xl" show={openModal} onClose={() => setOpenModal(false)}>
        <Modal.Header>{t("create-new-" + type)}</Modal.Header>
        <form onSubmit={handleAddCategory}>
          <Modal.Body>
            <div>
              <div>
                <div className='grid grid-cols-2 gap-2 mb-6'>
                  <div className="col-span-2">
                    <label className="flex flex-wrap items-center gap-1 mb-2 text-sm font-medium text-black">Title <span className='text-blue-600 text-xs'>(en)</span></label>
                    <input type="text" value={titleEn} onChange={(e) => setTitleEn(e.target.value)}
                      className="focus:ring-blue-700 focus:border-blue-700 placeholder:lowercase bg-gray-50 myborder text-black text-sm rounded-lg block w-full p-2.5" placeholder="Title" required />
                  </div>
                  <div className="col-span-2">
                    <label className="flex flex-wrap items-center gap-1 mb-2 text-sm font-medium text-black">Titre <span className='text-blue-600 text-xs'>(fr)</span></label>
                    <input type="text" value={titleFr} onChange={(e) => setTitleFr(e.target.value)}
                      className="focus:ring-blue-700 focus:border-blue-700 placeholder:lowercase bg-gray-50 myborder text-black text-sm rounded-lg block w-full p-2.5" placeholder="Titre" required />
                  </div>
                </div>

                <div className='grid grid-cols-2 gap-2 mb-2'>
                  <div className="col-span-2">
                    <label className="flex flex-wrap items-center gap-1 mb-2 text-sm font-medium text-black">Description <span className='text-blue-600 text-xs'>(en)</span></label>
                    <textarea rows="4" value={detailsEn} onChange={(e) => setDetailsEn(e.target.value)}
                      className="focus:ring-blue-700 focus:border-blue-700 placeholder:lowercase bg-gray-50 myborder text-black text-sm rounded-lg block w-full p-2.5" placeholder="description..." />
                  </div>
                  <div className="col-span-2">
                    <label className="flex flex-wrap items-center gap-1 mb-2 text-sm font-medium text-black">Description <span className='text-blue-600 text-xs'>(fr)</span></label>
                    <textarea rows="4" value={detailsFr} onChange={(e) => setDetailsFr(e.target.value)}
                      className="focus:ring-blue-700 focus:border-blue-700 placeholder:lowercase bg-gray-50 myborder text-black text-sm rounded-lg block w-full p-2.5" placeholder="description..." />
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button type='submit' className='py-2.5 px-4 bg-blue-600 hover:bg-blue-700 text-white rounded-md text-sm'>{t("create")}</button>
            <button className='py-2.5 px-4 bg-white hover:bg-gray-100 hover:text-blue-600 text-black rounded-md text-sm' onClick={() => setOpenModal(false)}>{t("cancel")}</button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  )
}
