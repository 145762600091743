import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';

export default function CopyBtn({ value }) {
  const { t } = useTranslation();
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(value).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    });
  };

  return (
    <button onClick={handleCopy} className='myborder px-3 py-2 font-semibold text-blue-700 text-sm rounded-md hover:bg-blue-100'>
      {copied ? t('copied') : t('copy')}
    </button>
  );
}
