import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import OrderCard from './OrderCard';

export default function ConfirmedOrders() {
  const { t } = useTranslation();
  const localhost = process.env.REACT_APP_LOCALHOST;

  const token = localStorage.getItem("token");
  const [myOrders, setMyOrders] = useState([]);

  useEffect(() => {
    axios.get(localhost + "/myconfirmedorders", { headers: { 'Authorization': `Bearer ${token}` } })
      .then((res) => {
        setMyOrders(res.data);
      }).catch((err) => console.error(err));
  }, []);

  const subtotal = myOrders.reduce((acc, { total_amount }) => acc + total_amount, 0).toFixed(2);

  return (
    <section className="relative">
      <div className="w-full max-w-7xl mx-auto">
        <div className="w-full flex-col justify-start items-start gap-8 inline-flex">
          <div className="w-full justify-center items-start ">
            {
              myOrders.map((item, index) => (
                <OrderCard key={index} data={item} />
              ))
            }
          </div>
          <div className="w-full p-5 rounded-xl border border-gray-200 flex-col justify-start items-center gap-4 flex">
            <div className="w-full justify-between items-center gap-6 inline-flex">
              <h5 className="text-black text-base font-normal leading-8">{t("subtotal")} :</h5>
              <h5 className="text-right text-black text-2xl font-semibold leading-8">$ {subtotal}</h5>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
