import React from 'react'
import TopBar from '../additions/TopBar'
import Header from '../additions/Header'
import Footer from '../additions/Footer'
import AllShop from './AllShop'

export default function Shop() {
  return (
    <section className=''>
      <TopBar />
      <Header />
      <div className='bg-gray-50'>
        <AllShop />
      </div>
      <Footer />
    </section>
  )
}
