import axios from 'axios';
import { PayPalButtons, PayPalScriptProvider } from '@paypal/react-paypal-js'
import OwnToast from '../../general additions/OwnToast';
import { useTranslation } from 'react-i18next';
import { useRefresh } from '../../general additions/RefreshProvider';
import { useNavigate } from 'react-router';

export default function PaypalButton({ orderData, activeBtn }) {
  const { t } = useTranslation();
  const { triggerRefresh } = useRefresh();
  const navigate = useNavigate();
  const token = localStorage.getItem('token');
  const localhost = process.env.REACT_APP_LOCALHOST;

  const handleCreateOrder = async () => {
    try {
      const response = await axios.post(`${localhost}/paypal/create`, { amount: orderData.amount }, { headers: { 'Authorization': `Bearer ${token}` } });
      return response.data.order?.id;
    } catch (err) {
      console.error(err);
    }
  };

  const handleApprove = async (data) => {
    try {
      const response = await axios.post(`${localhost}/paypal/execute`, { orderID: data.orderID, orderData: orderData }, { headers: { 'Authorization': `Bearer ${token}` } });
      OwnToast('success', t('success-paypal'));
      triggerRefresh();
      setTimeout(() => { navigate("/order/thankyou") }, 2000);
    } catch (err) {
      console.error(err);
      OwnToast('error', t('error-paypal'));
    }
  };

  return (
    <PayPalScriptProvider options={{ "client-id": "AW2M-QO3MN8W7UwmQlu9sO9_PKIy3uZ57KhWbg9xdw3zPPLHGtDzrQHZCS8K7LdbiFekfuIccp0OOsAW" }}>
      <PayPalButtons disabled={activeBtn} className="z-0" style={{ layout: "horizontal", color: 'blue', shape: 'rect', label: 'paypal', tagline: false }} createOrder={handleCreateOrder} onApprove={handleApprove} />
    </PayPalScriptProvider>
  );
};