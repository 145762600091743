import React, { useEffect, useState } from 'react'
import { useRefresh } from '../../general additions/RefreshProvider';
import { useTranslation } from 'react-i18next';
import { BsSearch } from "react-icons/bs";
import AddCategory from '../admin elements/AddCategory';
import axios from 'axios';
import i18next from 'i18next';
import { LoadingShopPart } from '../../general additions/LoadingElements';
import { notresultsfound } from '../../assets/images';
import UpdateCategory from '../admin elements/UpdateCategory';
import DeleteDiscount from '../admin elements/DeleteDiscount';

export default function LicensesList() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const localhost = process.env.REACT_APP_LOCALHOST;
  const token = localStorage.getItem("token");
  const { refreshKey } = useRefresh();
  const [licenses, setLicenses] = useState([]);
  const [filterLicenses, setFilterLicenses] = useState([]);
  const [search, setSearch] = useState("");

  useEffect(() => {
    axios.get(`${localhost}/admin/licenses`, { headers: { 'Authorization': `Bearer ${token}` } })
      .then((res) => {
        setLicenses(res.data);
        setFilterLicenses(res.data);
        setLoading(false);
      }).catch((err) => console.error(err));
  }, [refreshKey]);

  const handleSearch = (e) => {
    e.preventDefault();
    const lowercasedSearch = search.toLowerCase();
    const filtered = licenses.filter((license) =>
      license.title[i18next.language].toLowerCase().includes(lowercasedSearch)
    );
    setFilterLicenses(filtered);
  };

  return (
    <div className="antialiased">
      <div className="flex justify-between gap-2 items-center max-sm:p-1.5 mb-3 max-sm:mb-0">
        <p className="text-black font-semibold text-xl max-sm:text-lg">{t("special-licenses")}</p>
        <AddCategory IsThisLicense={true} />
      </div>

      <div className="">
        <div className="p-3 shadow-sm border rounded-lg bg-white">
          <form onSubmit={handleSearch} className="flex gap-1 w-full">
            <input className="myborder rounded-md text-sm w-full text-black placeholder:text-gray-400 py-1.5 px-2"
              placeholder={t("search-input-licenses")} type="text" value={search} onChange={(e) => setSearch(e.target.value)} />
            <button type="submit" className="h-[34px] min-w-[34px] flex justify-center items-center rounded-md bg-blue-600 hover:bg-blue-700 text-white">
              <BsSearch className="w-5 h-5" />
            </button>
          </form>
        </div>

        {/* Table */}
        <div className="relative overflow-x-auto rounded-lg bg-white mt-3 border shadow-sm">
          {loading ? (
            <div className='flex justify-center items-center h-[50vh] w-full'>
              <LoadingShopPart />
            </div>
          ) : filterLicenses.length > 0 ? (
            <table className="w-full text-sm text-left rtl:text-right text-gray-500">
              <thead className="text-xs text-gray-700 bg-gray-50">
                <tr>
                  <th className="px-6 py-3">{t("license")} ID</th>
                  <th className="px-6 py-3">{t("title")}</th>
                  <th className="px-6 py-3">{t("details")}</th>
                  <th className="px-6 py-3">Actions</th>
                </tr>
              </thead>
              <tbody>
                {filterLicenses.map((item, index) => (
                  <tr key={index} className="bg-white border-b">
                    <th scope="row" className="px-6 py-4 font-semibold text-gray-900 whitespace-nowrap">
                      {item.licenseID}
                    </th>
                    <td className="px-6 py-4">{item.title[i18next.language]}</td>
                    <td className="px-6 py-4 max-w-64">{item.details[i18next.language]}</td>
                    <td className="flex flex-wrap items-center gap-2 px-6 py-4">
                      <UpdateCategory category_id={item.licenseID} IsThisLicense={true} />
                      <DeleteDiscount discount_id={item.licenseID} typeProp="license" />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <div className="flex justify-center items-center py-10 text-gray-500">
              <div className="col-span-4 text-center text-black flex flex-col gap-6 justify-center items-center min-h-[40vh]">
                <img src={notresultsfound} className="w-48 h-auto" />
                {t("no-results")}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
