import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { BsSearch } from "react-icons/bs";
import { LoadingShopPart } from '../../general additions/LoadingElements';
import { Route, Routes, useNavigate } from "react-router";
import PaypalTable from './transactionsTables/PaypalTable';
import BankCardsTable from './transactionsTables/BankCardsTable';

export default function TransactionsList() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const localhost = process.env.REACT_APP_LOCALHOST;
  const token = localStorage.getItem("token");
  const [loading, setLoading] = useState(true);
  const [transactions, setTransactions] = useState([]);
  const [transactionsFilter, setTransactionsFilter] = useState([]);
  const [search, setSearch] = useState("");
  const [activeTable, setActiveTable] = useState("Paypal");

  useEffect(() => {
    const fetchTransactions = async () => {
      const response = await axios.get(`${localhost}/admin/transactionslist`, { headers: { Authorization: `Bearer ${token}` } });
      setTransactions(response.data);
      setTransactionsFilter(response.data);
      setLoading(false);
    };

    fetchTransactions();
  }, []);

  const handleSearch = (e) => {
    e.preventDefault();
    const lowercased = search.toLowerCase();
    const filtered = transactions.filter((item) =>
      item.fullname?.toLowerCase().includes(lowercased) ||
      item.country?.toLowerCase().includes(lowercased) ||
      item.email?.toLowerCase().includes(lowercased) ||
      item.phone?.toLowerCase().includes(lowercased)
    );
    setTransactionsFilter(filtered);
  };

  return (
    <div className="antialiased">
      <div className="flex justify-start gap-2 items-center max-sm:p-1.5 mb-3 max-sm:mb-0">
        <p className="text-black font-semibold text-xl max-sm:text-lg">Transactions</p>
      </div>

      <div className="">
        <div className="rounded-lg bg-white mt-3 border shadow-sm p-3 flex justify-between max-md:flex-col gap-2">
          <form onSubmit={handleSearch} className="flex gap-1 sm:min-w-[24rem]">
            <input type="text" value={search} onChange={(e) => setSearch(e.target.value)} placeholder={t("search-input")} className="myborder rounded-md text-sm w-full text-black placeholder:text-gray-400 py-1.5 px-2" />
            <button type="submit" className="h-9 min-w-9 flex justify-center items-center rounded-md bg-blue-600 hover:bg-blue-700 text-white">
              <BsSearch className="w-5 h-5" />
            </button>
          </form>

          <div className='flex flex-wrap items-center justify-end gap-1'>
            {["Paypal", "bank-cards"].map((item, index) => (
              <button key={index} onClick={() => setActiveTable(item)} style={{ border: '1px solid black' }} 
              className={`${activeTable === item?'text-white bg-black':"text-black bg-transparent"} hover:bg-black hover:text-white font-semibold rounded-full px-3 py-2 text-xs`}>{t(item)}</button>
            ))}
          </div>
        </div>

        {/* Table */}
        <div className="relative overflow-x-auto rounded-lg bg-white mt-3 border shadow-sm">
          {loading ? (
            <div className="flex justify-center items-center h-[50vh] w-full">
              <LoadingShopPart />
            </div>
          ) : (
            <div>
              {activeTable.toLowerCase() === 'paypal' ? <PaypalTable data={transactionsFilter} /> : <BankCardsTable data={transactionsFilter} />}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
