import React from 'react'
import { useTranslation } from 'react-i18next'

export default function PaymentMethods() {
  const { t } = useTranslation();

  return (
    <div className="col-span-8 overflow-hidden rounded-xl sm:bg-gray-50">
      <h1 className="py-2 text-2xl text-black font-semibold">{t("payment")}</h1>
      <hr className="mt-4 mb-8" />
      <div className='mb-5'>
        <button className='text-white mt-4 sm:mt-0 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 focus:outline-none'>{t("add-payment")}</button>
      </div>
    </div>
  )
}
