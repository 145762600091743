import axios from "axios";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import TopBar from "../ecrans/additions/TopBar";
import OwnToast from "../general additions/OwnToast";

export default function Register() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const localhost = process.env.REACT_APP_LOCALHOST;
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password1, setPassword1] = useState("");
  const [password2, setPassword2] = useState("");

  const signup = async (e) => {
    e.preventDefault();
    try {
      if (password1 === password2) {
        const userData = { "username": username, "email": email, "password": password1 };
        const signupResponse = await axios.post(`${localhost}/register`, userData);
        localStorage.setItem('token', signupResponse.data.token);

        axios.get(`${localhost}/user`, { headers: { 'Authorization': `Bearer ${signupResponse.data.token}` } })
          .then((res) => {
            localStorage.setItem('username', res.data.username);
            OwnToast('success', t('s-register'));
            setTimeout(() => { navigate("/") }, 500);
          }).catch((err) => {
            OwnToast('error', t('e-register'));
          });
      } else {
        OwnToast('error', t('error-not-match'));
      }
    } catch (err) {
      if (err.request && err.request.status === 422) {
        const errormsg = JSON.parse(err.request.response);
        if (errormsg.errors.username) {
          OwnToast('error', t(errormsg.errors.username[0]));
        }
        if (errormsg.errors.email) {
          OwnToast('error', t(errormsg.errors.email[0]));
        }
      } else {
        OwnToast('error', t('e-register'));
      }
    }
  }

  return (
    <section className="bg-gray-50">
      <TopBar />
      <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
        <div className="flex items-center mb-6 text-2xl font-semibold text-black">
          LOGO
        </div>
        <div className="w-full bg-white rounded-lg shadow-lg md:mt-0 sm:max-w-md xl:p-0">
          <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
            <h1 className="text-xl font-bold leading-tight tracking-tight text-black md:text-2xl">
              {t("create-acc")}
            </h1>
            <form onSubmit={signup} className="space-y-4 md:space-y-6">
              <div>
                <label htmlFor="username" className="block mb-2 text-sm font-medium text-black">{t("username")}</label>
                <input type="text" value={username} onChange={(e) => setUsername(e.target.value)} name="username" id="username" className="bg-gray-50 border border-gray-300 text-black rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5" placeholder="joe.doe" required />
              </div>
              <div>
                <label htmlFor="email" className="block mb-2 text-sm font-medium text-black">{t("email")}</label>
                <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} name="email" id="email" className="bg-gray-50 border border-gray-300 text-black rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5" placeholder="name@example.com" required />
              </div>
              <div>
                <label htmlFor="password" className="block mb-2 text-sm font-medium text-black">{t("password")}</label>
                <input type="password" value={password1} onChange={(e) => setPassword1(e.target.value)} name="password" id="password" placeholder="••••••••" className="bg-gray-50 border border-gray-300 text-black rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5" required />
              </div>

              <div>
                <label htmlFor="password2" className="block mb-2 text-sm font-medium text-black">{t("conf-password")}</label>
                <input type="password" value={password2} onChange={(e) => setPassword2(e.target.value)} name="password" id="password2" placeholder="••••••••" className="bg-gray-50 border border-gray-300 text-black rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5" required />
              </div>

              <div>
                <div className="flex flex-wrap items-center">
                  <input type="checkbox" className="w-4 h-4 mr-2 border-2 border-gray-500 rounded bg-gray-200 focus:ring-3 focus:ring-blue-300" required />
                  <label htmlFor="terms" className="flex flex-wrap text-sm font-light text-gray-500">{t("i-accept")} <a className="font-medium text-blue-600 hover:underline  ml-1" href="#_">{t("terms")}</a></label>
                </div>
              </div>

              <button type="submit" className="w-full text-white bg-blue-600 hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">{t("register")}</button>
              <p className="flex flex-wrap gap-1 text-sm font-light text-gray-500">
                {t("already-have")}<a href="/login" className="font-medium text-blue-600 hover:underline">{t("login")}</a>
              </p>
            </form>
          </div>
        </div>
      </div>
    </section>
  )
}
