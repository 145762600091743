import PuffLoader from "react-spinners/PuffLoader";

export const LoadingShopPart = ({ sizeProp = 100 }) => {
  return (
    <PuffLoader
      color={"#111827"}
      size={sizeProp}
      aria-label="Loading Spinner"
      data-testid="loader"
    />
  )
}
