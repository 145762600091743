import React from 'react'
import Header from '../additions/Header'
import Banner from './Banner'
import ProductsList from './ProductsList'
import WhyUs from './WhyUs'
import Services from './Services'
import ProcessOfPurchase from './ProcessOfPurchase'
import Testimonials from './Testimonials'
import Footer from '../additions/Footer'
import TopBar from '../additions/TopBar'

export default function Home() {
  return (
    <section className=''>
      <TopBar />
      <Header />
      <div className='bg-gray-50'>
        <Banner />
        <ProductsList />
        <WhyUs />
        <Services />
        <ProcessOfPurchase />
        <Testimonials />
        <Footer />
      </div>
    </section>
  )
}
