import axios from "axios";

export const getUserInfo = async () => {
  const localhost = process.env.REACT_APP_LOCALHOST;
  const token = localStorage.getItem('token');

  if (!token) {
    return null; 
  }

  try {
    const response = await axios.get(localhost + '/user', { headers: { Authorization: `Bearer ${token}` } });
    return response.data;
  } catch (error) {
    console.error('Error fetching user info:', error);
    localStorage.clear();
    window.location.reload();
    return null;
  }
};