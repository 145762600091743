import i18next from "i18next";

export default function SystemRequirements({ data }) {
  return (
    <div className="mt-6">
      <dl className="divide-y divide-gray-300">
        {
          data.map((item, index) => (
            <div key={index} className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt className="text-sm/6 font-medium text-gray-900">{item[i18next.language].title}</dt>
              <dd className="mt-1 text-sm/6 text-gray-700 sm:col-span-2 sm:mt-0">{item[i18next.language].details}</dd>
            </div>
          ))
        }
      </dl>
    </div>
  )
}
