import { useTranslation } from 'react-i18next'
import { checked, dollar, email, location } from '../../assets/icons'

export default function Services() {
  const { t } = useTranslation();
  const services = [
    {
      title: "serv-1",
      detail: "det-serv-1",
      img: checked
    },
    {
      title: "serv-2",
      detail: "det-serv-2",
      img: dollar
    },
    {
      title: "serv-3",
      detail: "det-serv-3",
      img: email
    },
    {
      title: "serv-4",
      detail: "det-serv-4",
      img: location
    },
  ]
  return (
    <section className="container py-10 relative">
      <div className="w-full max-w-7xl px-4 md:px-5 lg:px-5 mx-auto">
        <div className="w-full flex-col justify-start items-center lg:gap-11 gap-8 inline-flex">
          <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 justify-start items-start gap-8">
            {
              services.map((item, index) => (
                <div key={index} className="w-full px-6 py-8 rounded-2xl border border-gray-200 hover:border-blue-600 transition-all duration-700 ease-in-out justify-center items-center gap-2.5 flex">
                  <div className="flex-col justify-start items-center gap-3.5 inline-flex">
                    <img src={item.img} alt='' width="40" height="40" />
                    <h4 className="text-center text-black text-lg font-semibold leading-8">{t(item.title)}</h4>
                    <p className="text-center text-gray-500 text-sm font-normal leading-snug">{t(item.detail)}</p>
                  </div>
                </div>
              ))
            }
          </div>
        </div>
      </div>
    </section>

  )
}
