import axios from 'axios';
import { Modal } from 'flowbite-react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import RatingStars from './RatingStars';
import { HiMinus, HiPlus } from 'react-icons/hi2';
import { MdAddShoppingCart } from "react-icons/md";
import { officeIcon } from '../assets/icons';
import FavoriteBtn from './FavoriteBtn';
import { useRefresh } from '../general additions/RefreshProvider';
import { blankimage } from '../assets/images';
import OwnToast from '../general additions/OwnToast';
import { GetCategory } from '../general additions/getCategories';
import { GetLicense } from '../general additions/getLicenses';

export default function AddCart({ prodID, prodSlug, quantity, typeBtn, stockProd }) {
  const { t } = useTranslation();
  const localhost = process.env.REACT_APP_LOCALHOST;
  const localhostImage = process.env.REACT_APP_LOCALHOST_IMG;
  const token = localStorage.getItem("token");
  const [prod, setProd] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [quantit, setQuantit] = useState(1);
  const { triggerRefresh } = useRefresh();
  const [discount, setDiscount] = useState([]);
  const [discountText, setDiscountText] = useState([]);
  const [exist, setExist] = useState(false);

  const fetchData = async () => {
    try {
      const res = await axios.get(`${localhost}/productreview/${prodSlug}`);
      const productData = res.data[0];
      productData.name = JSON.parse(productData.name);
      productData.details = JSON.parse(productData.details);
      setProd(productData);

      // check discount :
      const dis = await axios.get(`${localhost}/admin/getdiscountsbyid/${prodID}`);
      setDiscount(dis.data);
      setDiscountText(dis.data.percentage ? parseInt(dis.data.percentage) + '%' : parseFloat(dis.data.amount) + '$');
      setExist(dis.data.discountID ? true : false);

      setOpenModal(true);
    } catch (err) {
      // console.error(err);
    }
  };

  const handleAddCart = () => {
    if (token) {
      const orderData = { "quantity": typeBtn === "noModal" ? quantity : quantit, "status": "pending", "product_id": prodID };
      axios.post(localhost + "/orders", orderData, { headers: { 'Authorization': `Bearer ${token}` } })
        .then((res) => {
          setTimeout(() => {
            triggerRefresh();
            setOpenModal(false);
            OwnToast('success', t('s-add-cart'));
          }, 500);
        }).catch((err) => {
          OwnToast('error', t('e-add-cart'));
          console.error(err);
        });

    } else {
      let cart = JSON.parse(localStorage.getItem('shoppingCart')) || [];
      const newItem = {
        product_id: prodID,
        product_slug: prodSlug,
        quantity: typeBtn === "noModal" ? quantity : quantit,
        status: "pending"
      };

      const existingItemIndex = cart.findIndex(item => item.product_id === newItem.product_id);
      if (existingItemIndex !== -1) {
        cart[existingItemIndex].quantity += newItem.quantity;
      } else {
        cart.push(newItem);
      }
      localStorage.setItem('shoppingCart', JSON.stringify(cart));

      setTimeout(() => {
        triggerRefresh();
        setOpenModal(false);
        OwnToast('success', t('s-add-cart'));
      }, 500);
    }
  };

  return (
    <>
      <button disabled={stockProd < 1 ? true : false} onClick={typeBtn === "noModal" ? handleAddCart : fetchData} className="text-white mt-4 sm:mt-0 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-[12px] py-2 pl-2 pr-3 focus:outline-none flex items-center justify-center gap-1 disabled:bg-blue-400 disabled:cursor-not-allowed">
        <MdAddShoppingCart className='w-5 h-5' />
        {t("addcart")}
      </button>

      {prod && (
        <Modal show={openModal} onClose={() => setOpenModal(false)}>
          <Modal.Header className='text-base'>{prod.name[i18n.language]}</Modal.Header>
          <Modal.Body>
            <div className="md:grid md:grid-cols-2 md:gap-6 xl:gap-8 items-center">
              <div className="relative max-w-md mx-auto">
                <a href={"/product/" + prod.slug}>
                  <img className={`w-full ${prod.stock > 0 ? "" : "grayscale"}`} src={prod.image ? localhostImage + prod.image : blankimage} alt={prod.name[i18n.language]} />
                  {exist ? <span className={`absolute top-2 right-2 bg-blue-600 text-white text-xs px-2 py-1 rounded-full`}>
                    -{discountText}
                  </span> : null}
                </a>
              </div>

              <div className='max-md:mt-6'>
                <div className="flex items-center gap-2 justify-center text-black w-48 text-[12px] font-semibold ring-1 ring-gray-900 rounded-full px-2.5 py-1 mb-2">
                  <GetCategory category_id={prod.category_id} />
                </div>
                <a href={"/product/" + prod.slug} className="text-lg text-black font-semibold mb-2 hover:text-blue-700">{prod.name[i18n.language] || prod.name.en}</a>
                <div className="flex items-center gap-2 mb-2">
                  <RatingStars size={4} rating={prod.ratings} />
                  <p className="text-sm font-medium text-gray-500">({prod.ratings})</p>
                  <p className="text-sm font-medium text-black underline hover:no-underline">
                    {prod.countreview} {t("reviews")}
                  </p>
                </div>

                <p className="text-2xl font-extrabold text-black mb-2">
                  {exist ? <span className="text-red-700 line-through text-xs font-medium">${prod.price.toFixed(2)}</span> : null}
                  ${exist ? discount.newPrice.toFixed(2) : prod.price.toFixed(2)}
                </p>

                <div className='flex flex-wrap items-center gap-2 mb-2 text-black'>
                  <span className='font-semibold'>{t("license")}</span>
                  <GetLicense license_id={prod.license_id} />
                </div>

                <div className="flex items-center">
                  <button onClick={() => setQuantit(Math.max(1, quantit - 1))}
                    className="flex justify-center items-center disabled:text-gray-300 bg-gray-200 hover:bg-gray-300 rounded-md h-7 w-7" disabled={quantit <= 1}>
                    <HiMinus className="w-5 h-5" />
                  </button>

                  <input type="text" value={quantit} onChange={(e) => setQuantit(Math.min(Math.max(1, parseInt(e.target.value) || 1), prod.stock))}
                    className="text-center w-14 border-0 ring-0 focus:ring-0" />

                  <button onClick={() => setQuantit(prevQuantit => Math.min(prod.stock, prevQuantit + 1))}
                    className="flex justify-center items-center disabled:text-gray-300 bg-gray-200 hover:bg-gray-300 rounded-md h-7 w-7" disabled={quantit >= prod.stock}>
                    <HiPlus className="w-5 h-5" />
                  </button>
                </div>


                <div className="flex flex-col gap-1 mt-3">
                  <button onClick={handleAddCart} className="text-white mt-4 sm:mt-0 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-[12px] px-5 py-2.5 focus:outline-none flex items-center justify-center">
                    <svg className="w-5 h-5 -ms-2 me-2" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                      <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 4h1.5L8 16m0 0h8m-8 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4Zm8 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4Zm.75-3H7.5M11 7H6.312M17 4v6m-3-3h6" />
                    </svg>
                    {t("addcart")}
                  </button>
                  {
                    token ? <FavoriteBtn prodID={prod.productID} /> : null
                  }
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
}
