import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { HiPlus } from "react-icons/hi";
import { BsSearch } from "react-icons/bs";
import { useRefresh } from '../../general additions/RefreshProvider';
import ProdCard from '../admin elements/ProdCard';
import { LoadingShopPart } from '../../general additions/LoadingElements';
import { notresultsfound } from '../../assets/images';

export default function ProductList() {
  const { t, i18n } = useTranslation();
  const [products, setProducts] = useState([]);
  const [originalProducts, setOriginalProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const localhost = process.env.REACT_APP_LOCALHOST;
  const { refreshKey } = useRefresh();
  const [search, setSearch] = useState("");
  const [categoryFilter, setCategoryFilter] = useState("All products");
  const [availabilityFilter, setAvailabilityFilter] = useState("All");

  useEffect(() => {
    axios.get(`${localhost}/products`)
      .then((res) => {
        setProducts(res.data);
        setOriginalProducts(res.data);
        setLoading(false);
      })
      .catch((err) => console.error(err));
  }, [refreshKey]);

  const applyFilters = () => {
    const filteredProducts = originalProducts.filter((product) => {
      const matchesCategory =
        categoryFilter === "All products" ||
        product.category?.en === t(categoryFilter, { lng: "en" });
      const matchesAvailability =
        availabilityFilter === "All" ||
        (availabilityFilter === "Available" && product.stock > 0) ||
        (availabilityFilter === "Unavailable" && product.stock <= 0);
      const matchesSearch =
        !search || product.name[i18n.language]?.toLowerCase().includes(search.toLowerCase());
      return matchesCategory && matchesAvailability && matchesSearch;
    });

    setProducts(filteredProducts);
  };

  useEffect(applyFilters, [search, categoryFilter, availabilityFilter]);

  if (loading)
    return (
      <div className="flex justify-center items-center min-h-[80vh] bg-white shadow p-4 max-sm:p-1.5 rounded-md antialiased">
        <LoadingShopPart sizeProp={120} />
      </div>
    );

  return (
    <div className="antialiased">
      <div className="flex justify-between gap-2 items-center max-sm:p-1.5 mb-3 max-sm:mb-0">
        <p className="text-black font-semibold text-xl max-sm:text-lg">{t("product-list")}</p>
        <div>
          <a href="/admin/dashboard/add_product" className="flex flex-wrap gap-1 items-center text-sm bg-blue-600 hover:bg-blue-700 text-white pl-2 pr-3 py-2 max-sm:p-1.5 rounded-md">
            <HiPlus className="h-[18px] w-[18px]" />
            <span className="max-sm:hidden">{t("add-prod")}</span>
          </a>
        </div>
      </div>

      <div className="pb-4 max-sm:py-1.5">
        <div className="shadow-sm border rounded-lg flex justify-between max-md:flex-col gap-2 p-3 bg-white">
          <form onSubmit={(e) => e.preventDefault()} className="flex gap-1 sm:min-w-[24rem]">
            <input type="text" value={search} onChange={(e) => setSearch(e.target.value)} placeholder={t("search-input")} className="myborder rounded-md text-sm w-full text-black placeholder:text-gray-400 py-1.5 px-2" />
            <button type="submit" className="h-9 min-w-9 flex justify-center items-center rounded-md bg-blue-600 hover:bg-blue-700 text-white">
              <BsSearch className="w-5 h-5" />
            </button>
          </form>

          <div className="flex flex-wrap gap-2 max-sm:grid max-sm:grid-cols-2">
            {/* Stock */}
            <select value={availabilityFilter} onChange={(e) => setAvailabilityFilter(e.target.value)} className="col-span-1 sm:w-36 rounded-lg myborder bg-white px-2 py-1.5 text-sm font-medium text-black hover:border-gray-300 focus:ring-4 focus:ring-gray-100">
              <option value="All">{t("all")}</option>
              <option value="Available">{t("available")}</option>
              <option value="Unavailable">{t("unavailable")}</option>
            </select>

            {/* Category */}
            <select value={categoryFilter} onChange={(e) => setCategoryFilter(e.target.value)} className="col-span-1 sm:w-36 rounded-lg myborder bg-white px-2 py-1.5 text-sm font-medium text-black hover:border-gray-300 focus:ring-4 focus:ring-gray-100">
              <option value="All products">{t("All-products")}</option>
              <option value="Microsoft office">{t("Microsoft-office")}</option>
              <option value="Office products">{t("Office-products")}</option>
              <option value="Operating system">{t("Operating-system")}</option>
              <option value="Windows servers">{t("Windows-servers")}</option>
              <option value="Antivirus & security">{t("Antivirus-security")}</option>
              <option value="Bundles">{t("Bundles")}</option>
            </select>
          </div>
        </div>


        <div className="py-3 grid grid-cols-6 max-2xl:grid-cols-6 max-xl:grid-cols-5 max-lg:grid-cols-4 max-md:grid-cols-3 max-sm:grid-cols-1 gap-3 min-h-20">
          {products.map((item, index) => (
            <ProdCard key={index} data={item} />
          ))}
          {!products.length > 0 ? (
            <div className="col-span-7 text-center text-black flex flex-col gap-6 justify-center items-center min-h-[50vh]">
              <img src={notresultsfound} className="w-48 h-auto" />
              {t("no-results")}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}