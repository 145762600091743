import { Modal } from 'flowbite-react'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useRefresh } from '../../general additions/RefreshProvider';
import axios from 'axios';
import DeleteDiscount from './DeleteDiscount';
import { LoadingShopPart } from '../../general additions/LoadingElements';

export default function GetDiscountsById({ productId }) {
  const { t } = useTranslation();
  const { triggerRefresh } = useRefresh();
  const token = localStorage.getItem('token');
  const localhost = process.env.REACT_APP_LOCALHOST;
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [discounts, setDiscounts] = useState([]);
  const [filteredDiscounts, setFilteredDiscounts] = useState([]);

  const modalClose = () => { setOpenModal(false) };
  const modalOpen = () => { setOpenModal(true) };

  const getSpecificDiscounts = () => {
    modalOpen();
    axios.get(`${localhost}/admin/getdiscountsbyproductid/${productId}`, { headers: { 'Authorization': `Bearer ${token}` } })
      .then((res) => {
        setDiscounts(res.data);
        setFilteredDiscounts(res.data);
        setLoading(false);
        triggerRefresh();
      }).catch((err) => console.error(err));
  };

  const handleFilter = (e) => {
    const selectedFilter = e.target.value;
    const currentDate = new Date();

    let filtered;
    if (selectedFilter === "active") {
      filtered = discounts.filter((discount) => {
        const startDate = new Date(discount.start_date);
        const endDate = new Date(discount.end_date);
        return currentDate >= startDate && currentDate <= endDate;
      });
    } else if (selectedFilter === "expired") {
      filtered = discounts.filter((discount) => {
        const endDate = new Date(discount.end_date);
        return currentDate > endDate;
      });
    } else {
      // Show all
      filtered = discounts;
    }

    setFilteredDiscounts(filtered);
  };


  return (
    <>
      <button onClick={getSpecificDiscounts} disabled={productId === null ? true : false} className='myborder disabled:text-gray-400 disabled:hover:bg-white disabled:cursor-not-allowed px-2 py-1.5 text-black text-sm rounded-md hover:bg-gray-100'>
        {t("see-discounts")}
      </button>

      <Modal size="4xl" show={openModal} onClose={modalClose}>
        <Modal.Header>{t("discount-list")}</Modal.Header>
        <Modal.Body className='p-2'>
          {
            loading ? <div className='flex justify-center items-center h-[40vh] w-full'>
              <LoadingShopPart />
            </div>
              : <div>
                <select onChange={handleFilter}
                  className="float-end mb-3 flex items-center justify-center rounded-lg myborder bg-white w-max px-3 py-1.5 text-sm font-medium text-black hover:border-[#D1D5DB] focus:z-10 focus:outline-none focus:border-gray-200 focus:ring-4 focus:ring-gray-100 sm:w-auto">
                  <option value="">All</option>
                  <option value="active">Active</option>
                  <option value="expired">Expired</option>
                </select>

                <table className="w-full text-sm text-left rtl:text-right text-gray-500">
                  <thead className="text-xs text-gray-700 bg-gray-50">
                    <tr>
                      <th className="px-2 py-3">{t("discount")} ID</th>
                      <th className="px-2 py-3">Type</th>
                      <th className="px-2 py-3">{t("percentage")}</th>
                      <th className="px-2 py-3">{t("amount")}</th>
                      <th className="px-2 py-3">{t("startdate")}</th>
                      <th className="px-2 py-3">{t("enddate")}</th>
                      <th className="px-2 py-3">{t("delete")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      filteredDiscounts.map((item, index) => (
                        <tr key={index} className="bg-white border-b">
                          <th scope="row" className="px-2 py-3 font-medium text-gray-900 whitespace-nowrap">
                            {item.uniqueDiscount}
                          </th>
                          <td className="px-2 py-3">{item.type}</td>
                          <td className="px-2 py-3">{item.percentage === null ? "- -" : item.percentage + "%"}</td>
                          <td className="px-2 py-3">{item.amount === null ? "- -" : item.amount}</td>
                          <td className="px-2 py-3">{item.start_date}</td>
                          <td className="px-2 py-3">{item.end_date}</td>
                          <td className="px-2 py-3">
                            <DeleteDiscount discount_id={item.discountID} typeProp="discount" />
                          </td>
                        </tr>
                      ))
                    }
                  </tbody>
                </table>
              </div>
          }
        </Modal.Body>
      </Modal>
    </>
  )
}
