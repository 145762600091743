import React from 'react'
import { FormatDateTableUsers } from '../../../elements/FormatDate';
import { useTranslation } from 'react-i18next';

export default function TransactionsTable({ data }) {
  const { t } = useTranslation();

  return (
    <div className="relative overflow-x-auto sm:rounded-lg">
      <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
        <thead className="text-xs text-gray-700 uppercase bg-gray-200 dark:bg-gray-700 dark:text-gray-400">
          <tr>
            <th scope="col" className="px-6 py-3">Transaction ID</th>
            <th scope="col" className="px-6 py-3">{t("date&time")}</th>
            <th scope="col" className="px-6 py-3">{t("amount")}</th>
            <th scope="col" className="px-6 py-3">{t("payment-method")}</th>
            <th scope="col" className="px-6 py-3">Status</th>
          </tr>
        </thead>
        <tbody>
          {
            data.map((item, index) => (
              <tr key={index} className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-200 even:dark:bg-gray-800 border-b dark:border-gray-700">
                <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                  {item.uniquePayment}
                </th>
                <td className="px-6 py-4">
                  <FormatDateTableUsers dateString={item.created_at} />
                </td>
                <td className="px-6 py-4">
                  ${item.amount}
                </td>
                <td className="px-6 py-4">
                  {item.paymentmethod}
                </td>
                <td className="px-6 py-4">
                  {
                    item.paymentstatus.toLowerCase() === "completed" ?
                      <p className='text-green-600 bg-green-200 rounded-md px-2 text-center w-max'>Completed</p>
                      : item.paymentstatus.toLowerCase() === "cancelled" ?
                        <p className='text-red-600 bg-red-200 rounded-md px-2 text-center w-max'>Cancelled</p>
                        : null
                  }
                </td>
              </tr>
            ))
          }
        </tbody>
      </table>
    </div>
  )
}
