import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const savedLanguage = localStorage.getItem('language') || 'en';

i18n
  // .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: {
          "welcome-table": "Here are all your products",
          "product": "Product",
          "products": "Products",
          "name": "Product name",
          "price": "Price",
          "category": "Category",
          "title": "Title",
          "details": "Details",
          "ratings": "Ratings",
          "dateaddition": "Date of addition",
          "admin": "Admin",
          "view": "View",
          "edit": "Edit",
          "delete": "Delete",
          "titleBanner": "Click to add a product",
          "textBanner": "The administrator can add, update or delete products.",
          "shop-cart": "Shopping cart",
          "cart": "Cart",
          "checkout": "Checkout",
          "subtotal": "Subtotal",

          // Home :
          "login": "Login",
          "register": "Register",
          "home": "Home",
          "shop": "Shop",
          "about": "About",
          "search-input": "Search products...",
          "search-input-categories": "Search categories...",
          "search-input-licenses": "Search licenses...",
          "search-input-orders": "Search orders...",
          "search-input-customers": "Search customers...",
          "search-input-discounts": "Search discounts...",
          "search-res": "Search result",
          "best-sellers": "Best Sellers",
          "sort": "Sort",
          "popular": "The most popular",
          "newest": "Newest",
          "oldest": "Oldest",
          "inc-price": "Increasing price",
          "dec-price": "Decreasing price",

          "proc-title-1": "1. Add a product to the shopping cart",
          "proc-detail-1": "Use the quick browsing function to search for the software you need. Make your selection and add to your cart.",
          "proc-title-2": "2. Apply promocode at checkout",
          "proc-detail-2": "For extra savings, remember to check the homepage for any discount codes — Often, special promotions are running.",
          "proc-title-3": "3. Receive activation key & download link in mailbox",
          "proc-detail-3": "No frustrating shipping delays. Instead, get a download link in your mailbox. Install and start using your product right away.",
          "process-title": "Download your software in seconds",
          "process-detail": "Name's website has made the process of software download as easy as 1-2-3.",
          "serv-1": "Reliable Service",
          "serv-2": "Cheap prices",
          "serv-3": "Email delivery",
          "serv-4": "Global licenses",
          "det-serv-1": "All Goods are checked manually to ensure maximal efficacy.",
          "det-serv-2": "All Our software keys are substantially cheaper than our competitors.",
          "det-serv-3": "Receive your software keys within seconds. No delays.",
          "det-serv-4": "No region restrictions on products purchased.",
          "testimonials": "Testimonials",
          "testimonials-1": "Hear What Our Happy Customers Have to Say",

          // categories :
          "All-products": "All products",
          "Microsoft-office": "Microsoft office",
          "microsoft-products": "Microsoft products",
          "Operating-system": "Operating system",
          "Windows-servers": "Windows servers",
          "Antivirus-security": "Antivirus & security",
          "Bundles": "Bundles",

          "upload-image1": "Click to upload",
          "upload-image2": "the product image",
          "add-prod": "Add product",
          "edit-prod": "Edit product",

          // Review :
          "addreview": "Add a review",
          "outof": "out of",
          "review-desc": "The comment",
          "cancel": "Cancel",
          "reviews": "Reviews",
          "addfav": "Add to my favorites",
          "nofav": "Remove from my favorites",
          "addcart": "Add to cart",
          "prolist-1": "Instant digital download",
          "prolist-2": "Lifetime support",
          "writereview": "Write a review",
          "viewmore": "View more reviews",
          "viewless": "View less reviews",

          // other :
          "removeorder": "Remove the product",
          "removeorder-1": "Are you sure you want to remove this product?",
          "removeprod": "Remove the product",
          "removeprod-1": "Are you sure you want to remove this product?",
          "yes": "Yes",

          // Shop :
          "our-products": "Our Products",
          "product-categories": "Product Categories",
          "available": "Available",
          "unavailable": "Unavailable",
          "availability": "Availability",


          // Logout :
          "logout": "Logout",
          "logout-msg": "are you sure you want to log out?",

          // Account setting :
          "account": "Account",
          "hello": "Hello",
          "hello-1": "From your account dashboard you can view your recent orders, manage your shipping and billing addresses, and edit your password and account details.",
          "orders": "Orders",
          "dashboard": "Dashboard",
          "payment": "Payment methods",
          "account-details": "Account details",

          "all-orders": "All my orders",
          "all-orders-1": "No order has been made yet.",
          "all-orders-2": "Browse products",

          "purchases": "Purchases",
          "my-purchases": "My purchases",
          "all-purchases": "All my purchases",
          "all-purchases-1": "No purchase has been made yet.",
          "all-purchases-2": "Browse products",

          "add-payment": "Add payment method",

          "fullname": "Full name",
          "fname": "First name",
          "lname": "Last name",
          "save": "Save",
          "leave-blank": "leave blank to leave unchanged",

          "username": "Username",
          "edit-username": "Your current username is",
          "new-name": "You can add the full name here",
          "edit-name": "Your current full name is",
          "edit-email": "Email Address",
          "edit-email-1": "Your current email is",
          "edit-password": "Password change",
          "edit-password-1": "Do you want to change your",
          "edit-password-2": "current password",
          "current-password": "Current password",
          "new-password": "New password",
          "conf-new-password": "Confirm new password",
          "error-not-match": "Passwords do not match",
          "error-username": "The username has already been taken",

          // Not Found :
          "error-1": "Something's missing",
          "error-2": "Sorry, we can't find that page. You'll find lots to explore on the home page.",
          "error-3": "Back to Homepage",
          "error-displaying-infos": "Error displaying informations",

          // Login :
          "login-1": "Login to your account",
          "email": "Email",
          "username-email": "Username or email",
          "password": "Password",
          "remember-me": "Remember me",
          "forgot-password": "Forgot password?",
          "msg-login": "Don’t have an account yet?",

          // Register :
          "create-acc": "Create an account",
          "conf-password": "Confirm password",
          "i-accept": "I accept the",
          "terms": "Terms and Conditions",
          "already-have": "Already have an account?",

          // Toasts : 
          "s-login": "Login successful!",
          "e-login": "Login failed. Please try again.",
          "s-register": "Registration successful!",
          "e-register": "Registration failed. Please try again.",
          "s-add-cart": "Product added to cart!",
          "e-add-cart": "Failed to add product to cart. Please try again.",
          "s-remove-cart": "Product removed from cart!",
          "e-remove-cart": "Failed to remove product from cart. Please try again.",
          "s-add-fav": "Product added to favorites!",
          "e-add-fav": "Failed to add product to favorites. Please try again.",
          "s-remove-fav": "Product removed from favorites!",
          "e-remove-fav": "Failed to remove product from favorites. Please try again.",
          "s-logout": "Logged out successfully!",
          "e-logout": "Logout failed. Please try again.",
          "s-add-rev": "Review added successfully!",
          "e-add-rev": "Failed to add review. Please try again.",
          // here 
          "s-remove-rev": "Review removed successfully!",
          "e-remove-rev": "Failed to remove review. Please try again.",
          "s-up-username": "Username updated successfully!",
          "e-up-username": "Failed to update username. Please try again.",
          "s-up-name": "Full name updated successfully!",
          "e-up-name": "Failed to update full name. Please try again.",
          "s-up-email": "Email updated successfully!",
          "e-up-email": "Failed to update email. Please try again.",
          "s-up-psw": "Password updated successfully!",
          "e-up-psw": "Failed to update password. Please try again.",


          // Header :
          "favorites": "Favorites",
          "settings": "Settings",

          // Search :
          "no-results": "No result",

          // Warning :
          "warning-checkout-title": "Important Notice",
          "warning-checkout-text": "To proceed with the checkout, you need to sign up on our website. Please create an account or log in to continue with your purchase.",

          // checkout :
          "phone": "Phone",
          "ur-phone": "Your phone",
          "country": "Country",
          "ur-country": "Your country",
          "postcode": "Post code",
          "ur-postcode": "Your post code",
          "ppayment": "Payment",
          "creditcard": "Credit card",
          "cardnumber": "Card number",
          "cardexpiration": "Card expiration",
          "placeorder": "Place order",

          // Dashboard :
          "customers": "Customers",
          "offers": "Offers",
          "product-list": "Product list",
          "categories": "Categories",

          // dashboard toasts :
          "add-product-success": "Product added successfully!",
          "add-product-error": "Failed to add product.",
          "update-product-success": "Product updated successfully!",
          "update-product-error": "Failed to update product.",
          "delete-product-success": "Product deleted successfully!",
          "delete-product-error": "Failed to delete product.",

          "total-amount": "Total amount",
          "quantity": "Quantity",

          "percentage": "Percentage",
          "amount": "Amount",
          "startdate": "Start date",
          "enddate": "End date",
          "create": "Create",
          "coupon": "Coupon",
          "discount": "Discount",
          "coupons": "Coupons",
          "discounts": "Discounts",
          "err-code-unique": "The code has already been taken.",

          // coupons & discounts :
          "coupon-list": "Coupon List",
          "special-coupons": "Special Coupons",
          "add-coupon": "Add Coupon",
          "add-coupon-success": "Coupon added successfully!",
          "add-coupon-error": "Failed to add coupon.",
          "delete-coupon-success": "Coupon deleted successfully!",
          "delete-coupon-error": "Failed to delete coupon.",
          "create-new-coupon": "Create New Coupon",

          "category-list": "Category List",
          "add-category": "Add Category",
          "edit-category": "Edit Category",
          "update-category": "Update Category",
          "update-category-success": "Category updated successfully!",
          "update-category-error": "Failed to update category.",
          "add-category-success": "Category added successfully!",
          "add-category-error": "Failed to add category.",
          "delete-category-success": "Category deleted successfully!",
          "delete-category-error": "Failed to delete category.",
          "create-new-category": "Create New Category",

          "discount-list": "Discount List",
          "special-discounts": "Special Discounts",
          "add-discount": "Add Discount",
          "add-discount-success": "Discount added successfully!",
          "add-discount-error": "Failed to add discount.",
          "delete-discount-success": "Discount deleted successfully!",
          "delete-discount-error": "Failed to delete discount.",
          "create-new-discount": "Create New Discount",

          "remove-coupon": "Remove Coupon",
          "remove-coupon-txt": "Are you sure you want to remove this coupon?",
          "remove-discount": "Remove Discount",
          "remove-discount-txt": "Are you sure you want to remove this discount?",
          "remove-category": "Remove Category",
          "remove-category-txt": "Are you sure you want to remove this category?",

          "all-coupons": "All coupons",
          "active-coupons": "Active coupons",
          "expired-coupons": "Expired coupons",
          "all-discounts": "All discounts",
          "active-discounts": "Active discounts",
          "expired-discounts": "Expired discounts",

          "add": "Add",
          "all": "All",
          "registration-date": "Registration date",
          "see-discounts": "See discounts",
          "msg-checkbox": "Check the box to enable the code input field.",
          "up-to-discount": "Up to {{value}} discount",

          "start>end": "Start date cannot be later than the end date.",
          "end<now": "End date cannot be earlier than the current date.",
          "discount-already-exist-1": "The product already has an active discount.",
          "discount-already-exist-2": "Please remove the existing discount before adding a new one.",
          "copy": "Copy code",
          "copied": "Code copied!",
          "download-coupon": "Download coupon",

          // More...
          "system-req": "System requirements",
          "add-system-req": "Add another system requirement",
          "remove-item": "Remove item",
          "remove-item-txt": "Are you sure you want to remove this item?",

          "license": "License",
          "licenses": "Licenses",
          "special-licenses": "Special Licenses",
          "special-categories": "Special Categories",
          "license-list": "License List",
          "add-license": "Add License",
          "edit-license": "Edit License",
          "update-license": "Update License",
          "update-license-success": "License updated successfully!",
          "update-license-error": "Failed to update license.",
          "add-license-success": "License added successfully!",
          "add-license-error": "Failed to add license.",
          "delete-license-success": "License deleted successfully!",
          "delete-license-error": "Failed to delete license.",
          "create-new-license": "Create New License",
          "remove-license": "Remove License",
          "remove-license-txt": "Are you sure you want to remove this license?",

          "type-licence": "Type of licence",
          "duration": "Duration",
          "days": "Days",
          "months": "Months",
          "years": "Years",
          "filter": "Filter",
          "no-requirements": "There are no system requirements",
          "favorites-list": "Favorites list",

          "success-paypal": "Payment successful. Thank you for your purchase!",
          "error-paypal": "Payment failed. Please try again or contact support.",

          "my-transactions": "My transactions",
          "all-transactions": "All my transactions",
          "all-transactions-1": "No transaction has been made yet.",
          "date&time": "Date & time",
          "payment-method": "Payment method",

          "thankyou": "Thank You",
          "confirmation-purchase-title": "Thank You for Your Purchase!",
          "confirmation-purchase-text-1": "Your order is being processed.",
          "confirmation-purchase-text-2": "You will receive a confirmation email with your digital product license shortly.",
          "confirmation-purchase-text-3": "If you have any questions or need assistance, please contact our support team.",
          "browse-purchase": "Browse your purchases",

          "error-unique-username": "This username is already taken.",
          "error-unique-email": "This email is already registered.",
          "placeholder-code-coupon": "Enter your coupon code...",
          "coupon-expired": "Coupon has expired.",
          "coupon-not-exist": "Coupon code does not exist.",
          "coupon-not-valid": "Coupon is not yet valid. It will be available from",
          "bank-cards": "Bank cards",
          "card-number": "Card number",
          "expiration-date": "Expiration date",
          "pending": "Pending",
          "cancelled": "Cancelled",
          "completed": "Completed",

        }
      },
      fr: {
        translation: {
          "welcome-table": "Voici tous vos produits",
          "product": "Produit",
          "products": "Produits",
          "name": "Nom du produit",
          "price": "Prix",
          "category": "Catégorie",
          "title": "Titre",
          "details": "Détails",
          "ratings": "Notes",
          "dateaddition": "Date d'ajout",
          "admin": "Administrateur",
          "view": "Voir",
          "edit": "Éditer",
          "delete": "Supprimer",
          "titleBanner": "Cliquez pour ajouter un produit",
          "textBanner": "L'administrateur peut ajouter, mettre à jour ou supprimer des produits.",
          "shop-cart": "Panier d'achats",
          "cart": "Panier",
          "checkout": "Sortie de caisse",
          "subtotal": "Sous total",

          // Home :
          "login": "S'identifier",
          "register": "S'inscrire",
          "home": "Accueil",
          "shop": "Boutique",
          "about": "À propos",
          "search-input": "Rechercher un produits...",
          "search-input-categories": "Rechercher des catégories...",
          "search-input-licenses": "Rechercher des licences...",
          "search-input-orders": "Rechercher des commandes...",
          "search-input-customers": "Rechercher des clients...",
          "search-input-discounts": "Rechercher des remises...",
          "search-res": "Résultats de la recherche",
          "best-sellers": "Meilleures ventes",
          "sort": "Trier",
          "popular": "Les plus populaires",
          "newest": "Le plus récent",
          "oldest": "Le plus ancien",
          "inc-price": "Prix croissant",
          "dec-price": "Prix en baisse",
          "proc-title-1": "1. Ajoutez un produit au panier",
          "proc-detail-1": "Utilisez la fonction de navigation rapide pour rechercher le logiciel dont vous avez besoin. Faites votre sélection et ajoutez-la à votre panier.",
          "proc-title-2": "2. Appliquez le code promo lors du paiement",
          "proc-detail-2": "Pour des économies supplémentaires, pensez à vérifier la page d'accueil pour tout code de réduction — Souvent, des promotions spéciales sont en cours.",
          "proc-title-3": "3. Recevez la clé d'activation et le lien de téléchargement dans votre boîte mail",
          "proc-detail-3": "Pas de retards d'expédition frustrants. Recevez un lien de téléchargement dans votre boîte mail. Installez et commencez à utiliser votre produit immédiatement.",
          "process-title": "Téléchargez votre logiciel en quelques secondes",
          "process-detail": "Le site web de Name a rendu le processus de téléchargement de logiciels aussi facile que 1-2-3.",
          "serv-1": "Service fiable",
          "serv-2": "Prix bas",
          "serv-3": "Livraison par e-mail",
          "serv-4": "Licences globales",
          "det-serv-1": "Toutes les marchandises sont contrôlées manuellement pour garantir une efficacité maximale.",
          "det-serv-2": "Toutes les clés de nos logiciels sont nettement moins chères que celles de nos concurrents.",
          "det-serv-3": "Recevez vos clés logicielles en quelques secondes. Aucun délai.",
          "det-serv-4": "Aucune restriction régionale sur les produits achetés.",
          "testimonials": "Témoignages",
          "testimonials-1": "Écoutez ce que nos clients satisfaits ont à dire",

          // categories :
          "All-products": "Tous les produits",
          "Microsoft-office": "Microsoft office",
          "microsoft-products": "Produits de microsoft",
          "Operating-system": "Système d'exploitation",
          "Windows-servers": "Serveurs Windows",
          "Antivirus-security": "Antivirus et sécurité",
          "Bundles": "Collection",

          "upload-image1": "Cliquez pour télécharger",
          "upload-image2": "l'image du produit",
          "add-prod": "Ajouter un produit",
          "edit-prod": "Éditer le produit",

          //Review 
          "addreview": "Ajouter un commentaire",
          "outof": "sur",
          "review-desc": "Le commentaire",
          "cancel": "Annuler",
          "reviews": "Commentaires",
          "addfav": "Ajouter à mes favoris",
          "nofav": "Retirer de mes favoris",
          "addcart": "Ajouter au panier",
          "prolist-1": "Téléchargement numérique instantané",
          "prolist-2": "Soutien à vie",
          "writereview": "Écrire un commentaire",
          "viewmore": "Voir d'autres commentaires",
          "viewless": "Voir moins de commentaires",

          // other :
          "removeorder": "Retirer le produit",
          "removeorder-1": "Êtes-vous sûr de vouloir retirer ce produit?",
          "removeprod": "Retirer le produit",
          "removeprod-1": "Êtes-vous sûr de vouloir supprimer ce produit?",
          "yes": "Oui",

          // Shop :
          "our-products": "Nos produits",
          "product-categories": "Catégories de produits",
          "available": "Disponible",
          "unavailable": "Indisponible",
          "availability": "Disponibilité",

          // Logout :
          "logout": "Déconnexion",
          "logout-msg": "êtes-vous sûr de vouloir vous déconnecter?",

          // Account setting :
          "account": "Compte",
          "hello": "Bonjour",
          "hello-1": "Depuis le tableau de bord de votre compte, vous pouvez voir vos commandes récentes, gérer vos adresses de livraison et de facturation, et modifier votre mot de passe et les détails de votre compte.",
          "orders": "Commandes",
          "dashboard": "Tableau de bord",
          "payment": "Moyens de paiement",
          "account-details": "Détails du compte",

          "purchases": "Achats",
          "my-purchases": "Mes achats",
          "all-purchases": "Tous mes achats",
          "all-purchases-1": "Aucun achat n'a encore été effectué.",
          "all-purchases-2": "Parcourir les produits",

          "all-orders": "Toutes mes commandes",
          "all-orders-1": "Aucune commande n'a encore été passée.",
          "all-orders-2": "Parcourir les produits",

          "add-payment": "Ajouter un moyen de paiement",

          "fullname": "Nom et prénom",
          "fname": "Prénom",
          "lname": "Nom de famille",
          "save": "Enregistrer",
          "leave-blank": "laisser vide pour ne pas changer",
          "username": "Nom d'utilisateur",
          "edit-username": "Votre nom d'utilisateur actuel est",
          "new-name": "Vous pouvez ajouter le nom complet ici",
          "edit-name": "Votre nom complet actuel est",
          "edit-email": "Adresse e-mail",
          "edit-email-1": "Votre e-mail actuel est",
          "edit-password": "Changement de mot de passe",
          "edit-password-1": "Voulez-vous changer votre",
          "edit-password-2": "mot de passe actuel?",
          "current-password": "Mot de passe actuel",
          "new-password": "Nouveau mot de passe",
          "conf-new-password": "Confirmer le nouveau mot de passe",
          "error-not-match": "Les mots de passe ne correspondent pas",
          "error-username": "Le nom d'utilisateur a déjà été utilisé",

          // Not Found :
          "error-1": "Il manque quelque chose",
          "error-2": "Désolé, nous ne trouvons pas cette page. Vous trouverez beaucoup de choses à explorer sur la page d'accueil.",
          "error-3": "Retour à la page d'accueil",
          "error-displaying-infos": "Erreur d'affichage des informations",

          // Login: 
          "login-1": "Se connecter à votre compte",
          "email": "Adresse e-mail",
          "username-email": "Nom d'utilisateur ou email",
          "password": "Mot de passe",
          "remember-me": "Souviens-toi de moi",
          "forgot-password": "Mot de passe oublié?",
          "msg-login": "Vous n'avez pas encore de compte?",

          // Register :
          "create-acc": "Créer un compte",
          "conf-password": "Confirmer le mot de passe",
          "i-accept": "J'accepte le",
          "terms": "Termes et Conditions",
          "already-have": "Avez-vous déjà un compte?",

          // Toast :
          "s-login": "Connexion réussie!",
          "e-login": "Échec de la connexion. Veuillez réessayer.",
          "s-register": "Inscription réussie!",
          "e-register": "Échec de l'inscription. Veuillez réessayer.",
          "s-add-cart": "Produit ajouté au panier!",
          "e-add-cart": "Échec de l'ajout du produit au panier. Veuillez réessayer.",
          "s-remove-cart": "Produit retiré du panier!",
          "e-remove-cart": "Échec de la suppression du produit du panier. Veuillez réessayer.",
          "s-add-fav": "Produit ajouté aux favoris!",
          "e-add-fav": "Échec de l'ajout du produit aux favoris. Veuillez réessayer.",
          "s-remove-fav": "Produit retiré des favoris!",
          "e-remove-fav": "Échec de la suppression du produit des favoris. Veuillez réessayer.",
          "s-logout": "Déconnexion réussie!",
          "e-logout": "Échec de la déconnexion. Veuillez réessayer.",
          "s-add-rev": "Avis ajouté avec succès!",
          "e-add-rev": "Échec de l'ajout de l'avis. Veuillez réessayer.",
          "s-remove-rev": "Avis supprimé avec succès!",
          "e-remove-rev": "Échec de la suppression de l'avis. Veuillez réessayer.",
          "s-up-username": "Nom d'utilisateur mis à jour avec succès!",
          "e-up-username": "Échec de la mise à jour du nom d'utilisateur. Veuillez réessayer.",
          "s-up-name": "Nom complet mis à jour avec succès!",
          "e-up-name": "Échec de la mise à jour du nom complet. Veuillez réessayer.",
          "s-up-email": "Email mis à jour avec succès!",
          "e-up-email": "Échec de la mise à jour de l'email. Veuillez réessayer.",
          "s-up-psw": "Mot de passe mis à jour avec succès!",
          "e-up-psw": "Échec de la mise à jour du mot de passe. Veuillez réessayer.",

          // Header :
          "favorites": "Favoris",
          "settings": "Paramètres",

          // Search :
          "no-results": "Aucun résultat",

          // Warning :
          "warning-checkout-title": "Avis Important",
          "warning-checkout-text": "Pour continuer avec le paiement, vous devez vous inscrire sur notre site Web. Veuillez créer un compte ou vous connecter pour continuer votre achat.",

          // checkout :
          "phone": "Téléphone",
          "ur-phone": "Votre téléphone",
          "country": "Pays",
          "ur-country": "Votre pays",
          "postcode": "Code postal",
          "ur-postcode": "Votre code postal",
          "ppayment": "Paiement",
          "creditcard": "Carte de crédit",
          "cardnumber": "Numéro de la carte",
          "cardexpiration": "Expiration de la carte",
          "placeorder": "Passer commande",

          // Dashboard :
          "customers": "Clients",
          "offers": "Offres",
          "product-list": "Liste des produits",
          "categories": "Catégories",

          // dashboard toasts :
          "add-product-success": "Produit ajouté avec succès!",
          "add-product-error": "Échec de l'ajout du produit.",
          "update-product-success": "Produit mis à jour avec succès!",
          "update-product-error": "Échec de la mise à jour du produit.",
          "delete-product-success": "Produit supprimé avec succès!",
          "delete-product-error": "Échec de la suppression du produit.",

          "total-amount": "Montant total",
          "quantity": "Quantité",

          "percentage": "Pourcentage",
          "amount": "Montant",
          "startdate": "Date de début",
          "enddate": "Date de fin",
          "create": "Créer",
          "coupon": "Coupon",
          "coupons": "Coupons",
          "discount": "Remise",
          "discounts": "Remises",
          "err-code-unique": "Le code a déjà été utilisé.",

          // coupons & discounts :
          "coupon-list": "Liste de coupons",
          "special-coupons": "Coupons spéciaux",
          "add-coupon": "Ajouter un coupon",
          "add-coupon-success": "Coupon ajouté avec succès!",
          "add-coupon-error": "Le coupon n'a pas été ajouté.",
          "delete-coupon-success": "Coupon supprimé avec succès!",
          "delete-coupon-error": "Échec de la suppression du coupon.",
          "create-new-coupon": "Créer un nouveau coupon",

          "category-list": "Liste des catégories",
          "add-category": "Ajouter une catégorie",
          "add-category-success": "Catégorie ajoutée avec succès !",
          "add-category-error": "Échec de l'ajout de la catégorie.",
          "edit-category": "Modifier la catégorie",
          "update-category": "Mise à jour de la catégorie",
          "update-category-success": "La catégorie a été mise à jour avec succès!",
          "update-category-error": "Échec de la mise à jour de la catégorie.",
          "delete-category-success": "Catégorie supprimée avec succès !",
          "delete-category-error": "Échec de la suppression de la catégorie.",
          "create-new-category": "Créer une nouvelle catégorie",

          // -----
          "discount-list": "Liste de remises",
          "special-discounts": "Remises spéciales",
          "add-discount": "Ajouter une remise",
          "add-discount-success": "Remise ajoutée avec succès!",
          "add-discount-error": "La remise n'a pas été ajoutée.",
          "delete-discount-success": "Remise supprimée avec succès!",
          "delete-discount-error": "Échec de la suppression de la remise.",
          "create-new-discount": "Créer une nouvelle remise",

          "remove-coupon": "Supprimer le coupon",
          "remove-coupon-txt": "Êtes-vous sûr de vouloir supprimer ce coupon?",
          "remove-discount": "Supprimer la remise",
          "remove-discount-txt": "Êtes-vous sûr de vouloir supprimer cette remise?",
          "remove-category": "Supprimer la catégorie",
          "remove-category-txt": "Êtes-vous sûr de vouloir supprimer cette catégorie?",

          "all-coupons": "Tous les coupons",
          "active-coupons": "Coupons actifs",
          "expired-coupons": "Coupons expirés",
          "all-discounts": "Tous les remises",
          "active-discounts": "Remises actives",
          "expired-discounts": "Remises expirées",

          "add": "Ajouter",
          "all": "Tout",
          "registration-date": "Date d'inscription",
          "see-discounts": "Voir les remises",
          "msg-checkbox": "Cochez la case pour activer le champ de saisie du code.",
          "up-to-discount": "Jusqu'à {{value}} de remise",

          "start>end": "La date de début ne peut pas être postérieure à la date de fin.",
          "end<now": "La date de fin ne peut pas être antérieure à la date actuelle.",
          "discount-already-exist-1": "Le produit a déjà une réduction active.",
          "discount-already-exist-2": "Veuillez supprimer la réduction existante avant d'en ajouter une nouvelle.",
          "copy": "Copier le code",
          "copied": "Code copié!",
          "download-coupon": "Télécharger coupon",

          // More...
          "system-req": "Exigences système",
          "add-system-req": "Ajouter une autre exigence de système",
          "remove-item": "Supprimer l'élément",
          "remove-item-txt": "Êtes-vous sûr de vouloir supprimer cet élément ?",

          "license": "Licence",
          "licenses": "Licences",
          "license-list": "Liste des licences",
          "special-licenses": "Licences spéciales",
          "special-categories": "Catégories spéciales",
          "add-license": "Ajouter une licence",
          "edit-license": "Modifier la licence",
          "update-license": "Mettre à jour la licence",
          "update-license-success": "Licence mise à jour avec succès !",
          "update-license-error": "Échec de la mise à jour de la licence.",
          "add-license-success": "Licence ajoutée avec succès !",
          "add-license-error": "Échec de l'ajout de la licence.",
          "delete-license-success": "Licence supprimée avec succès !",
          "delete-license-error": "Échec de la suppression de la licence.",
          "create-new-license": "Créer une nouvelle licence",
          "remove-license": "Supprimer la licence",
          "remove-license-txt": "Êtes-vous sûr de vouloir supprimer cette licence ?",

          "type-licence": "Type de licence",
          "duration": "Durée",
          "days": "Jours",
          "months": "Mois",
          "years": "Années",
          "filter": "Filtre",
          "no-requirements": "Il n'y a pas d'exigences de système",
          "favorites-list": "Liste des favoris",
          "success-paypal": "Paiement réussi. Merci pour votre achat !",
          "error-paypal": "Paiement échoué. Veuillez réessayer ou contacter le support.",

          "my-transactions": "Mes transactions",
          "all-transactions": "Toutes mes transactions",
          "all-transactions-1": "Aucune transaction n'a encore été effectuée.",
          "date&time": "Date et heure",
          "payment-method": "Mode de paiement",

          "thankyou": "Merci",
          "confirmation-purchase-title": "Merci pour votre achat !",
          "confirmation-purchase-text-1": "Votre commande est en cours de traitement.",
          "confirmation-purchase-text-2": "Vous recevrez un e-mail de confirmation avec votre licence de produit numérique sous peu.",
          "confirmation-purchase-text-3": "Si vous avez des questions ou besoin d'assistance, veuillez contacter notre équipe de support.",
          "browse-purchase": "Parcourir vos achats",

          "error-unique-username": "Ce nom d'utilisateur est déjà utilisé.",
          "error-unique-email": "Cet email est déjà enregistré.",
          "placeholder-code-coupon": "Entrez votre code coupon...",
          "coupon-expired": "Le coupon a expiré.",
          "coupon-not-exist": "Le code du coupon n'existe pas.",
          "coupon-not-valid": "Le coupon n'est pas encore valide. Il sera disponible à partir de",
          "bank-cards": "Cartes bancaires",
          "card-number": "Numéro de carte",
          "expiration-date": "Date d'expiration",
          "pending": "En attente",
          "cancelled": "Annulé",
          "completed": "Terminé",

        }
      }
    },
    lng: savedLanguage,
    fallbackLng: "en",
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
