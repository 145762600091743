import React, { useState } from 'react';
import { HiPlus } from "react-icons/hi";
import { PiPercentBold } from "react-icons/pi";
import { BiDollar } from "react-icons/bi";
import { Modal } from 'flowbite-react';
import { useTranslation } from 'react-i18next';
import OwnToast from '../../general additions/OwnToast';
import axios from 'axios';
import { useRefresh } from '../../general additions/RefreshProvider';

export default function AddCoupon() {
  const { t } = useTranslation();
  const { triggerRefresh } = useRefresh();
  const token = localStorage.getItem('token');
  const localhost = process.env.REACT_APP_LOCALHOST;
  const [openModal, setOpenModal] = useState(false);

  // Coupon :
  const [code, setCode] = useState("");
  const [err, setErr] = useState(false);
  const [percentage, setPercentage] = useState("");
  const [amount, setAmount] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const handleAddCoupon = (e) => {
    e.preventDefault();
    setErr(false);
    const couponData = {
      code: code,
      type: "coupon",
      percentage: percentage,
      amount: amount,
      start_date: startDate,
      end_date: endDate,
    };

    axios.post(`${localhost}/admin/discounts`, couponData, { headers: { 'Authorization': `Bearer ${token}` } })
      .then((res) => {
        OwnToast('success', t("add-coupon-success"));
        triggerRefresh();
        setOpenModal(false);
      })
      .catch((err) => {
        const error = JSON.parse(err.response.request.response);
        if (error.message) {
          OwnToast('error', t("err-code-unique"));
          setErr(true);
        } else {
          OwnToast('error', t("add-coupon-error"));
        }
      });
  }

  return (
    <>
      <button onClick={() => setOpenModal(true)} className="flex flex-wrap gap-1 items-center text-sm bg-blue-600 hover:bg-blue-700 text-white pl-2 pr-3 py-2 max-sm:p-1.5 rounded-md">
        <HiPlus className="h-[18px] w-[18px]" />
        <span className="max-sm:hidden">{t("add-coupon")}</span>
      </button>

      <Modal size="xl" show={openModal} onClose={() => setOpenModal(false)}>
        <Modal.Header>{t("create-new-coupon")}</Modal.Header>
        <form onSubmit={handleAddCoupon}>
          <Modal.Body>
            <div>
              <div>
                <div className='grid grid-cols-2 gap-3 mb-5'>
                  <div className="col-span-1">
                    <label className="block mb-2 text-sm font-medium text-black">Type</label>
                    <input type="text" disabled value="coupon" className="focus:ring-blue-700 focus:border-blue-700 placeholder:lowercase bg-gray-50 myborder text-black text-sm rounded-lg block w-full p-2.5" placeholder="Type" />
                  </div>
                  <div className="col-span-1">
                    <label className="block mb-2 text-sm font-medium text-black">Code</label>
                    <input type="text" value={code} onChange={(e) => setCode(e.target.value)} className={`${err ? "ring-2 ring-red-700 focus:ring-red-700 focus:border-red-700" : "focus:ring-blue-700 focus:border-blue-700"} placeholder:lowercase bg-gray-50 myborder text-black text-sm rounded-lg block w-full p-2.5`} placeholder="Code" required/>
                  </div>
                </div>

                <div className='grid grid-cols-2 gap-3 mb-5'>
                  <div className="col-span-1">
                    <label className="block mb-2 text-sm font-medium text-black">{t("percentage")}</label>
                    <div className="relative">
                      <input type="number" value={percentage} onChange={(e) => setPercentage(e.target.value)} className="placeholder:lowercase disabled:cursor-not-allowed bg-gray-50 myborder text-black text-sm rounded-lg focus:ring-blue-700 focus:border-blue-700 block w-full p-2.5" placeholder={t("percentage")}
                        disabled={amount ? true : false} />
                      <PiPercentBold className='absolute inset-y-0 end-2 top-[11px] flex items-center pointer-events-none w-auto h-5 text-gray-500' />
                    </div>
                  </div>
                  <div className="col-span-1">
                    <label className="block mb-2 text-sm font-medium text-black">{t("amount")}</label>
                    <div className='relative'>
                      <input type="number" value={amount} onChange={(e) => setAmount(e.target.value)} className="placeholder:lowercase disabled:cursor-not-allowed bg-gray-50 myborder text-black text-sm rounded-lg focus:ring-blue-700 focus:border-blue-700 block w-full p-2.5" placeholder={t("amount")}
                        disabled={percentage ? true : false} />
                      <BiDollar className='absolute inset-y-0 end-2 top-[11px] flex items-center pointer-events-none w-auto h-5 text-gray-500' />
                    </div>
                  </div>
                </div>

                <div className='grid grid-cols-2 gap-3 mb-5'>
                  <div className="col-span-1">
                    <label className="block mb-2 text-sm font-medium text-black">{t("startdate")}</label>
                    <input type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)} className="placeholder:lowercase bg-gray-50 myborder text-black text-sm rounded-lg focus:ring-blue-700 focus:border-blue-700 block w-full p-2.5" placeholder={t("startdate")} required/>
                  </div>
                  <div className="col-span-1">
                    <label className="block mb-2 text-sm font-medium text-black">{t("enddate")}</label>
                    <input type="date" value={endDate} onChange={(e) => setEndDate(e.target.value)} className="placeholder:lowercase bg-gray-50 myborder text-black text-sm rounded-lg focus:ring-blue-700 focus:border-blue-700 block w-full p-2.5" placeholder={t("enddate")} required/>
                  </div>
                </div>

              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button type='submit' className='py-2.5 px-4 bg-blue-600 hover:bg-blue-700 text-white rounded-md text-sm'>{t("create")}</button>
            <button className='py-2.5 px-4 bg-white hover:bg-gray-100 hover:text-blue-600 text-black rounded-md text-sm' onClick={() => setOpenModal(false)}>{t("cancel")}</button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  )
}
